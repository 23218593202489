import Pages from 'pages/Routes'
import Toast from 'components/Toast/Toast'
import LocaleService from 'utils/localization'
import AnalyticsService from 'utils/analytics'
import { ProvideAppContext } from 'utils/hooks/useContext'
import { useEffect, useState } from 'react'

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    AnalyticsService.init()
    LocaleService.init()
      .then(() => setIsLoaded(true))
      .catch((error: Error) => console.error(error))
  }, [])

  return (
    <ProvideAppContext>
      <Pages isLocaleLoaded={isLoaded} />
      <Toast />
    </ProvideAppContext>
  )
}

export default App
