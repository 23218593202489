import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import iconSearch from 'assets/svg/search.svg'
import { t } from 'utils/localization'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useDocuments } from 'utils/hooks/useContext'
import { IDocumentFilter } from 'utils/types'
import { useEffect, useState } from 'react'
import 'react-spring-bottom-sheet/dist/style.css'

interface DocumentFiltersProps {
  isOpen: boolean
  onClose(): void
  onChange: (
    name: string,
    filters: IDocumentFilter
  ) => (value: string | string[]) => IDocumentFilter
  docTypes: {
    icon: string
    value: string
    label: string
  }[]
}

const DocumentFilters: React.FC<DocumentFiltersProps> = (props) => {
  const { isOpen, onClose, onChange, docTypes } = props
  const { filters, setFilters } = useDocuments()

  const [innerFilters, setInnerFilters] = useState<IDocumentFilter>({
    text: '',
    types: []
  })

  useEffect(() => {
    setInnerFilters(filters)
  }, [filters])

  const handleClose = () => {
    onClose()
    setInnerFilters(filters)
  }

  const handleApply = () => {
    onClose()
    setFilters(innerFilters)
  }

  const handleChangeTypes = (value: string) => () => {
    const index = innerFilters.types.findIndex((item) => item === value)
    const selectedValues = [...innerFilters.types]

    if (index > -1) {
      selectedValues.splice(index, 1)
    } else {
      selectedValues.push(value)
    }

    const newValues = onChange('types', innerFilters)(selectedValues)
    setInnerFilters(newValues)
  }

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={handleClose}
      className='b-document-filters'
    >
      <div className='b-document-filters__inner'>
        <TextField
          size='lg'
          prefix={<img src={iconSearch} alt='' />}
          value={innerFilters.text}
          onChange={onChange('text', innerFilters)}
          className='b-documents__search-input'
          placeholder={t('document-search')}
        />

        <div className='b-spacer__3' />

        {docTypes.map((option) => (
          <div
            key={option.value}
            className='b-document-filters__option'
            onClick={handleChangeTypes(option.value)}
          >
            <div className='b-row b-row__align-center'>
              <Checkbox
                id={option.value}
                checked={innerFilters.types.includes(option.value)}
              />

              <Typography>{option.label}</Typography>
            </div>

            <img src={option.icon} alt='' />
          </div>
        ))}

        <div className='b-spacer__3' />

        <Button onClick={handleApply}>{t('ready')}</Button>
      </div>
    </BottomSheet>
  )
}

export default DocumentFilters
