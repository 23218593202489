import './styles.scss'

interface ErrorLabelProps {
  message?: string
}

const ErrorLabel: React.FC<ErrorLabelProps> = (props) => {
  const { message } = props

  return <div className='b-error__label'>{message}</div>
}

export default ErrorLabel
