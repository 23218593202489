import { ClipLoader } from 'react-spinners'

interface LoaderProps {
  size?: number
  color?: 'light' | 'primary'
  className?: string
}

const LOADER_COLORS = {
  light: 'white',
  primary: '#36A0F2'
}

const Loader: React.FC<LoaderProps> = (props) => {
  const { size = 36, color = 'primary', className } = props

  return (
    <div className={className}>
      <ClipLoader
        size={size}
        color={LOADER_COLORS[color]}
        speedMultiplier={0.6}
      />
    </div>
  )
}

export default Loader
