import Tabs from 'components/Tabs'
import { t } from 'utils/localization'
import VrPlatformTab from './components/VrPlatformTab'
import WebPlatformTab from './components/WebPlatformTab'
import './styles.scss'

const Help = () => {
  return (
    <div>
      <Tabs
        items={[
          {
            key: 'webplatform',
            title: t('web-platform')
          },
          {
            key: 'vrplatform',
            title: t('vr-platform')
          }
        ]}
      >
        <div>
          <WebPlatformTab />
        </div>
        <div>
          <VrPlatformTab />
        </div>
      </Tabs>
    </div>
  )
}

export default Help;
