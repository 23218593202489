import '@google/model-viewer'
import Modal from 'components/Modal'
import client from 'utils/socket'
import confirm from 'utils/confirm'
import iconArrow from 'assets/svg/arrow-circle-left.svg'
import iconDelete from 'assets/svg/trash.svg'
import LocaleService, { t } from 'utils/localization'
import { useDocuments } from 'utils/hooks/useContext'
import { IDocumentFile } from 'utils/types'

interface DocumentModalProps {
  isOpen: boolean
  onNext(): void
  onPrev(): void
  onClose(): void
  document?: IDocumentFile
}

const DocumentModal: React.FC<DocumentModalProps> = (props) => {
  const { setList } = useDocuments()
  const { isOpen, onPrev, onNext, onClose, document } = props

  const handleDelete = async () => {
    if (
      document &&
      (await confirm(t('confirm-delete-file'), {
        okText: t('delete'),
        style: 'warning'
      }))
    ) {
      try {
        await client.deleteDoc(document._id)
        onClose()
        setList((list) => list.filter((item) => item._id !== document._id))
      } catch {
        /* empty */
      }
    }
  }

  const renderContent = () => {
    switch (document?.type) {
      case 'image':
        return (
          <img src={document.url} alt='' className='b-document-modal__image' />
        )

      case 'video':
        var [, videoId] = document.url.split('v=')

        return videoId ? (
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?hl=${LocaleService.locale}`}
            title={document.title}
            width='100%'
            height='100%'
            frameBorder='0'
            allowFullScreen
          />
        ) : (
          <video width='100%' height='auto' controls>
            <source src={document.url} type='video/mp4' />
          </video>
        )

      case 'model':
        return (
          //@ts-ignore
          <model-viewer
            alt={document.title}
            src={document.url}
            enable-pan
            className='b-document-modal__iframe'
            camera-controls
          />
        )

      case 'link':
        return (
          <iframe
            src={document.url}
            title={document.title}
            className='b-document-modal__iframe'
          />
        )

      case 'document':
        switch (document.docType) {
          case 'pdf':
            var pdfUrl = document.url + '.pdf'
            return (
              <object
                data={pdfUrl}
                type='application/pdf'
                className='b-document-modal__iframe'
              >
                <iframe
                  src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`}
                  title={document.title}
                  className='b-document-modal__iframe'
                />
              </object>
            )

          case 'text':
            return <p className='b-document-modal__text'>{document.text}</p>
        }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className='b-document-modal'
      clickOutsideOptions={{
        ignoreClassNames: ['b-document-modal__icon']
      }}
      topElement={
        <div className='b-document-modal__header'>
          <div className='b-document-modal__menu'>
            <button onClick={handleDelete}>
              <img
                src={iconDelete}
                alt='x'
                className='b-document-modal__icon'
              />
            </button>
          </div>
          <div className='b-document-modal__controls'>
            <button
              className='b-document-modal__control b-document-modal__control--prev'
              onClick={onPrev}
            >
              <img
                src={iconArrow}
                alt='<-'
                className='b-document-modal__icon'
              />
            </button>
            <button
              className='b-document-modal__control b-document-modal__control--next'
              onClick={onNext}
            >
              <img
                src={iconArrow}
                alt='->'
                className='b-document-modal__icon'
              />
            </button>
          </div>
        </div>
      }
    >
      <div className='b-document-modal__content'>{renderContent()}</div>

      {document?.type !== 'video' && document?.type !== 'link' && (
        <div className='b-document-modal__overlay'>{document?.title}</div>
      )}
    </Modal>
  )
}

export default DocumentModal
