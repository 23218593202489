import { useIMask } from 'react-imask'
import { RefObject, useEffect } from 'react'
import TextField, { TextFieldProps } from 'components/TextField/TextField'

interface PriceTextFieldProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: number
  onChange(value: number): void
}

const PriceTextField = (props: PriceTextFieldProps) => {
  const { value, onChange, ...inputProps } = props

  const { ref, setUnmaskedValue } = useIMask(
    {
      mask: [
        {
          mask: `$num`,
          lazy: false,
          blocks: {
            num: {
              min: 0,
              max: 10000,
              mask: Number,
              expose: true,
              thousandsSeparator: ' ',
              overwrite: true,
              scale: 2,
              radix: '.',
              mapToRadix: [',']
            }
          }
        }
      ]
    },
    { onComplete: (_, maskRef) => onChange(maskRef.typedValue) }
  )

  useEffect(() => {
    setUnmaskedValue(value ? value + '' : '0.00')
  }, [value, setUnmaskedValue])

  return (
    <TextField inputRef={ref as RefObject<HTMLInputElement>} {...inputProps} />
  )
}

export default PriceTextField
