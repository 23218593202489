import Modal from 'components/Modal'
import Button from 'components/Button'
import Cropper from 'cropperjs'
import ReactCropper from 'react-cropper'
import { t } from 'utils/localization'
import { alerts } from 'components/Toast/Toast'
import { useState } from 'react'
import { IDocument } from 'utils/types'

import 'cropperjs/dist/cropper.css'

type TViewMode = 0 | 1 | 2 | 3
type TCropperCollback = (coords: string, originalImage: File) => void
type TCropperOptions = {
  viewMode?: TViewMode
  aspectRatio?: number
}

const useCropper = (callback: TCropperCollback, options?: TCropperOptions) => {
  const [cropperApi, setCropperApi] = useState<Cropper | null>(null)
  const [cropperModal, setCropperModal] = useState<{
    image: File | null
    isOpen: boolean
  }>({ image: null, isOpen: false })

  const crop = (image: File) => {
    setCropperModal({ image: image, isOpen: true })
  }

  const handleSave = () => {
    try {
      if (!cropperApi) {
        throw new Error('Cropper API is undefined')
      }

      const data = cropperApi.getData()

      const width = data.width
      const height = data.height

      const x = data.x + width / 2
      const y = data.y + height / 2

      const coords = `${x} ${y} ${width} ${height}`

      if (cropperModal.image) {
        callback(coords, cropperModal.image)
        handleClose()
      }
    } catch {
      alerts.error(t('unexpected-error'))
      handleClose()
    }
  }

  const handleClose = () => {
    setCropperModal({ image: null, isOpen: false })
  }

  const cropper = (
    <Modal isOpen={cropperModal.isOpen} onClose={handleClose}>
      <ReactCropper
        src={cropperModal.image ? URL.createObjectURL(cropperModal.image) : ''}
        viewMode={options?.viewMode || 0}
        className='b-cropper'
        aspectRatio={options?.aspectRatio}
        onInitialized={setCropperApi}
      />

      <div className='b-spacer__3' />

      <div className='b-row b-row__justify-end'>
        <Button onClick={handleSave}>{t('save')}</Button>
      </div>
    </Modal>
  )

  return { crop, cropper }
}

export default useCropper

export const cropImageByUrl = (file: IDocument, coords: string) => {
  return new Promise<string>((resolve) => {
    try {
      const canvas: any = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      var image = new Image()
      image.src = file.url
      image.crossOrigin = ''

      let [x, y, width, height] = coords.split(' ').map((v) => +v)

      x -= width / 2
      y -= height / 2

      image.onload = function () {
        canvas.width = width
        canvas.height = height
        ctx.drawImage(image, x, y, width, height, 0, 0, width, height)
        try {
          const dataUrl = canvas.toDataURL(file.mime)
          resolve(dataUrl)
        } catch (error) {
          resolve('')
        }
      }
    } catch (error) {
      resolve('')
    }
  })
}
