
import meta from 'assets/images/download_meta.png'
import win from 'assets/images/download_windows.png'
import htc from 'assets/images/download_vive.png'
import pico from 'assets/images/download_pico.png'

import { t } from "utils/localization";
import Typography from 'components/Typography';
import Button from 'components/Button';
import './styles.scss'


export enum PlatformTypes {
  Meta = "Meta",
  Windows = "Windows",
  Pico = "Pico",
  HTC = "HTC",
}

interface DownloadCardProps {
  onClick(): void;
  type: PlatformTypes;
  href?: string;
}
const wordingPerType = {
  Meta: {
    image: meta,
    title: 'meta_title',
    description: 'meta_description',
    buttonText: 'meta_buttonText'
  },
  Windows: {
    image: win,
    title: 'windows_title',
    description: 'windows_description',
    buttonText: 'windows_buttonText'
  },
  HTC: {
    image: htc,
    title: 'htc_title',
    description: 'htc_description',
    buttonText: 'htc_buttonText'
  },
  Pico: {
    image: pico,
    title: 'pico_title',
    description: 'pico_description',
    buttonText: 'pico_buttonText'
  }
}

const DownloadCard: React.FC<DownloadCardProps> = ({type, onClick, href}) => {
  const values = wordingPerType[type];
  return (
    <div className="download-card">
        <img src={values.image} alt={values.buttonText} />
        <div>
          <Typography size='md' weight='bold'>
            {t(values.title)}
          </Typography>
          <br/>
          <Typography size='xs'>
            {t(values.description)}
          </Typography>
        </div>
        <Button
          href={href}
          variant='primary'
          onClick={onClick}
          linkProps={{target: "_blank"}}
        >
          {t(values.buttonText)}
        </Button>
    </div>
  )
}

export default DownloadCard;
