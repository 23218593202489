import iconEye from 'assets/svg/eye.svg'
import iconEyeClosed from 'assets/svg/eye-slash.svg'
import TextField, { TextFieldProps } from 'components/TextField/TextField'
import { t } from 'utils/localization'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import '../TextField/styles.scss'

interface PasswordFieldProps extends TextFieldProps {
  linkState?: object
  hideRecovery?: boolean
}

const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const { hideRecovery, linkState, ...rest } = props

  const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <TextField
      {...rest}
      type={isVisible ? 'text' : 'password'}
      label={
        hideRecovery ? (
          props.label
        ) : (
          <div className='b-row b-row__justify-between'>
            {props.label}

            <Link to='/recovery' state={linkState} className='b-link'>
              {t('forgot-password')}
            </Link>
          </div>
        )
      }
      postfix={
        <button
          type='button'
          tabIndex={-1}
          onClick={() => setIsVisible(!isVisible)}
        >
          <img alt='' src={isVisible ? iconEyeClosed : iconEye} />
        </button>
      }
    />
  )
}

export default PasswordField
