import * as yup from 'yup'
import Button from 'components/Button'
import iconUser from 'assets/svg/user.svg'
// import Checkbox from 'components/Checkbox'
import TextField from 'components/TextField'
import useRequest from 'utils/hooks/useRequest'
import Typography from 'components/Typography'
import PasswordField from 'components/PasswordField'
import LoadingWrapper from 'components/LoadingWrapper'
import AnalyticsService from 'utils/analytics'
import { t } from 'utils/localization'
import { useState } from 'react'
import { useFormik } from 'formik'
import { useProfile } from 'utils/hooks/useContext'
import { useNavigate } from 'react-router-dom'
import {pushDataLayer} from "../../utils/gtm";
import iconLogo from 'assets/images/Logo_black_2.png'

interface ISigninForm {
  email: string
  password: string
  rememberMe: boolean
}

const SinginPage = () => {
  const [isLoading, setLoading] = useState<boolean>(false)

  const profile = useProfile()
  const navigate = useNavigate()

  const signinQuery = useRequest<{ token: string }>('/auth', {
    method: 'POST',
    onError(error, payload) {
      setLoading(false)
      if (error?.errorData?.needEmailConfirmation) {
        navigate('/success', { state: { email: payload?.email } })
      }
    },
    onSuccess(data) {
      AnalyticsService.track('Signin')
      profile.login(data.token, (id?: string) => {
        pushDataLayer({
          event: "sign_in",
          category: "Users",
          user_id: id,
          origin: window.location.origin,
        });
        setLoading(false)
        window.location.href = 'https://app.beyondreal.life/';  
      })
    }
  })

  const handleSignin = (values: ISigninForm) => {
    setLoading(true)
    signinQuery.request(values)
  }

  const handleSignup = () => {
    navigate('/signup', { state: values })
  }

  const { values, errors, touched, handleChange, handleSubmit } =
    useFormik<ISigninForm>({
      onSubmit: (values) => handleSignin(values),
      initialValues: {
        email: '',
        password: '',
        rememberMe: true
      },
      validationSchema: yup.object({
        email: yup.string().required(t('required')).email(t('invalid-email')),
        password: yup
          .string()
          .required(t('required'))
          .min(8, t('password-invalid')),
        rememberMe: yup.boolean()
      })
    })

  return (
    <form onSubmit={handleSubmit}>
      <LoadingWrapper isLoading={isLoading || signinQuery.isLoading}>
        <div className='b-signin__logo no-auth-logo'>
          <img src={iconLogo} alt='BEYONDREAL' />
        </div>
        <Typography centered size='lg' weight='semi'>
          {t('signin-to-inreal')}
        </Typography>

        <div className='b-spacer__4' />

        <TextField
          size='lg'
          name='email'
          label={t('email')}
          value={values.email}
          error={touched.email && errors.email}
          postfix={<img src={iconUser} alt='' />}
          placeholder={t('email')}
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__3' />

        <PasswordField
          size='lg'
          name='password'
          label={t('password')}
          value={values.password}
          error={touched.password && errors.password}
          linkState={{ email: values.email }}
          placeholder={t('password')}
          onChangeFormik={handleChange}
        />

        {/* <div className='b-spacer__2' />

        <Checkbox
          id='rememberMe'
          label={t('remember-computer')}
          checked={values.rememberMe}
          onChangeFormik={handleChange}
        /> */}

        <div className='b-spacer__5' />

        <Button size='lg' type='submit' fullWidth>
          {t('signin')}
        </Button>

        <div className='b-spacer__3' />

        <Typography centered>
          <button type='button' onClick={handleSignup} className='b-link'>
            {t('signup-to-inreal')}
          </button>
          !{' '}{t('welcome-to-computing')}
          
        </Typography>
      </LoadingWrapper>
    </form>
  )
}

export default SinginPage
