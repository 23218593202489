import { normalizeUrl } from './helpers'
import { FALLBACK_LOCALE } from './localization'
import { IQueryParams, IResponse, TRequestMethod } from './types'

interface IFailServerResponse {
  status: 'fail' | 'error'
  message?: string
  data?: {
    message: string
  }
}

interface ISuccessServerResponse<T> {
  data: T
  status: 'success'
}

interface PostOptions extends RequestInit {
  body: BodyInit | null
}

async function api<TData, TMethod extends TRequestMethod>(
  url: string,
  method: TMethod,
  options?: TMethod extends 'POST' | 'PUT' ? PostOptions : RequestInit
): Promise<IResponse<TData>> {
  const result: IResponse<TData> = { data: null, error: null }

  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('locale') || FALLBACK_LOCALE,
    ...options?.headers
  }

  const payload = {
    ...options,
    method: method,
    headers: headers
  }

  try {
    const response = await fetch(
      normalizeUrl(`${process.env.REACT_APP_HOST}/${url}`),
      payload
    )

    const json: IFailServerResponse | ISuccessServerResponse<TData> =
      await response.json()

    if (json.status === 'fail' || json.status === 'error') {
      result.error = {
        message: json.data?.message || json.message || 'Unknown error',
        errorData: json.data
      }
    } else if (json.status === 'success') {
      result.data = json.data
    } else {
      result.error = {
        message: 'Unknown server error'
      }
    }
  } catch (error) {
    result.error = {
      message: error instanceof Error ? error.message : 'Unknown error'
    }
  }

  return result
}

interface ApiRequestProps {
  url: string
  data?: object | FormData
  params?: IQueryParams
  method: TRequestMethod
}

const ApiService = <TData>({
  url,
  data,
  params,
  method,
  ...options
}: ApiRequestProps) => {
  return api<TData, TRequestMethod>(`${url}`, method, {
    body: data instanceof FormData ? data : JSON.stringify(data),
    ...options
  })
}

ApiService.get = <TData>(url: string, options: RequestInit = {}) => {
  return api<TData, 'GET'>(url, 'GET', options)
}

ApiService.put = <TData>(
  url: string,
  data: object,
  options: RequestInit = {}
) => {
  return api<TData, 'PUT'>(url, 'PUT', {
    body: JSON.stringify(data),
    ...options
  })
}

ApiService.post = <TData>(
  url: string,
  data?: object,
  options: RequestInit = {}
) => {
  return api<TData, 'POST'>(url, 'POST', {
    body: data ? JSON.stringify(data) : null,
    ...options
  })
}

ApiService.delete = (url: string, options: RequestInit = {}) => {
  return api<never, 'DELETE'>(url, 'DELETE', options)
}

export default ApiService
