import clsx from 'clsx'
import { Link } from 'react-router-dom'

interface SideMenuItemProps {
  url?: string
  icon: string
  label: string
  onClick?(): void
  isActive: boolean
  className?: string
}

const SideMenuItem: React.FC<SideMenuItemProps> = (props) => {
  const { url, icon, label, onClick, isActive, className } = props

  return (
    <div
      className={clsx(
        'b-side-menu__item',
        isActive && 'b-side-menu__item--active',
        className
      )}
    >
      {url && (
        <Link to={url} onClick={onClick}>
          <img src={icon} alt='' />
          {label}
        </Link>
      )}

      {!url && onClick && (
        <button type='button' onClick={onClick}>
          <img src={icon} alt='' />
          {label}
        </button>
      )}
    </div>
  )
}

export default SideMenuItem
