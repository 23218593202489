import iconVideo from 'assets/svg/arrow-circle-right.svg'

interface HelpItemProps {
  documentThumb: string
  title: string
  onSelect(): void
}

const HelpItem: React.FC<HelpItemProps> = (props) => {
  const { documentThumb, title, onSelect } = props

  const { icon, preview } = {
    icon: iconVideo,
    preview: (
      <img
        src={documentThumb || iconVideo}
        alt=''
        className={
            documentThumb ? undefined : 'b-help__preview'
        }
      />
    )
  };

  return (
    <div className='b-help' onClick={onSelect}>
      <div className='b-help__preview'>
        {preview}
        <div className='b-help__title'>{title}</div>
      </div>
      <div className='b-help__type'>
        <img src={icon} alt='' />
      </div>
    </div>
  )
}

export default HelpItem
