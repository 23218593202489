import clsx from 'clsx'
import iconArrow from 'assets/svg/arrow-up.svg'
import TextField from 'components/TextField'
import useOnClickOutside from 'utils/hooks/useOnClickOutside'
import { IInputProps, IOption } from 'utils/types'
import { useState, useEffect, useRef } from 'react'
import './styles.scss'

export interface SelectProps extends IInputProps {
  value?: string | string[]
  options: IOption[]
  multiple?: true
  disabled?: boolean
  className?: string
  renderLabel?: (options: IOption[], isVisible: boolean) => React.ReactNode
  renderOption?: (option: IOption, selected: boolean) => React.ReactNode
  onChange?(
    values: null | string | string[],
    options: null | IOption | IOption[]
  ): void
}

const Select: React.FC<SelectProps> = (props) => {
  const {
    value,
    options,
    multiple,
    disabled,
    onChange,
    className,
    renderLabel,
    renderOption,
    ...textFieldProps
  } = props

  const [selected, setSelected] = useState<IOption[]>([])
  const [isVisible, setVisible] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => setVisible(false))

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        setSelected(options.filter((option) => value.includes(option.value)))
      } else {
        const option = options.find((item) => item.value === value)
        setSelected(option ? [option] : [])
      }
    } else {
      setSelected([])
    }
  }, [value, options])

  const updateValue = (values: IOption[]) => {
    if (multiple && Array.isArray(values)) {
      onChange
        ? onChange(
            values.map((o) => o.value),
            values
          )
        : setSelected(values)
    } else {
      onChange
        ? onChange(values[0]?.value || null, values[0])
        : setSelected(values)
    }
  }

  const toggleVisibility = () => {
    setVisible(!isVisible)
  }

  const handleSelect = (option: IOption) => () => {
    if (multiple) {
      const index = selected.findIndex((item) => item.value === option.value)
      const selectedValues = [...selected]

      if (index > -1) {
        selectedValues.splice(index, 1)
      } else {
        selectedValues.push(option)
      }

      updateValue(selectedValues)
    } else {
      setVisible(false)
      updateValue([option])
    }
  }

  return (
    <div
      ref={ref}
      className={clsx('b-select', isVisible && 'b-select--open', className)}
    >
      <div onClick={toggleVisibility} className='b-select__toggle'>
        {renderLabel && selected.length ? (
          renderLabel(selected, isVisible)
        ) : (
          <TextField
            value={selected.map((s) => s.label).join(', ')}
            readOnly
            {...textFieldProps}
            postfix={
              <div className='b-select__arrow'>
                <img src={iconArrow} alt='' />
              </div>
            }
          />
        )}
      </div>

      {isVisible && (
        <div className='b-select__dropdown'>
          {options.map((option) => {
            const isSelected = !!selected.find((s) => s.value === option.value)

            return (
              <div
                key={option.value}
                onClick={handleSelect(option)}
                className={clsx(
                  'b-select__option',
                  isSelected && 'b-select__option--selected'
                )}
              >
                {renderOption ? renderOption(option, isSelected) : option.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Select
