import bgSignin from 'assets/images/SignIn.jpg'
import bgSignup from 'assets/images/SignUp.jpg'
import bgSuccess from 'assets/images/Congratulate.jpg'
import bgRecovery from 'assets/images/RecoverPassword.jpg'
import bgPlaceholder from 'assets/svg/figures.svg'
import bgPasswordReset from 'assets/images/ResetPassword.jpg'
import bgRecoverySuccess from 'assets/images/RecoverPasswordSuccess.jpg'
import bgPasswordResetSuccess from 'assets/images/ResetPasswordSuccess.jpg'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import './styles.scss'

const PAGE_BG_IMAGE: { [key: string]: string } = {
  '/signin': bgSignin,
  '/signup': bgSignup,
  '/subscribe': bgSignin,
  '/success': bgSuccess,
  '/recovery': bgRecovery,
  '/recovery/success': bgRecoverySuccess,
  '/change-password': bgPasswordReset,
  '/change-password/success': bgPasswordResetSuccess
}

interface AuthLayoutProps {
  backGroundImage?: string
}

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  const { pathname } = useLocation()
  const { backGroundImage } = props

  const bgImage = useMemo(() => {
    if (backGroundImage) {
      return (
        <img src={backGroundImage} alt='' className='b-auth-layout__bg-image' />
      )
    } else if (
      pathname.includes('/change-password') &&
      pathname !== '/change-password/success'
    ) {
      return (
        <img src={bgPasswordReset} alt='' className='b-auth-layout__bg-image' />
      )
    } else if (PAGE_BG_IMAGE[pathname]) {
      return (
        <img
          src={PAGE_BG_IMAGE[pathname]}
          alt=''
          className='b-auth-layout__bg-image'
        />
      )
    } else {
      return <img src={bgPlaceholder} alt='' />
    }
  }, [pathname, backGroundImage])

  return (
    <div className='b-auth-layout'>
      {/* <div className='b-auth-layout__locale'>
        <LanguageSelect isAuthLayout />
      </div> */}

      <div className='b-auth-layout__bg'>{bgImage}</div>
      <div className='b-auth-layout__form'>{props.children}</div>
    </div>
  )
}

export default AuthLayout
