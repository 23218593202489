import * as yup from 'yup'
import clsx from 'clsx'
import Button from 'components/Button'
import iconUser from 'assets/svg/user.svg'
import useTimer from 'utils/hooks/useTimer'
import TextField from 'components/TextField'
import useRequest from 'utils/hooks/useRequest'
import Typography from 'components/Typography'
import LoadingWrapper from 'components/LoadingWrapper'
import { t } from 'utils/localization'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

interface ILocationState {
  email?: string
}

interface IRecoveryForm {
  email: string
}

const PasswordRecovery = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { timer, setTimer, resendInText } = useTimer(0)

  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  useEffect(() => {
    setShowSuccess(params.status === 'success')
  }, [params.status])

  const recoverQuery = useRequest('/password-recovery', {
    method: 'POST',
    onSuccess() {
      setTimer(60)
      navigate('/recovery/success')
    }
  })

  const handleRecover = (values: IRecoveryForm) => {
    recoverQuery.request(values)
  }

  const { values, errors, touched, handleChange, handleSubmit } =
    useFormik<IRecoveryForm>({
      onSubmit: (values) => handleRecover(values),
      initialValues: {
        email: (state as ILocationState)?.email || ''
      },
      validationSchema: yup.object({
        email: yup.string().required(t('required')).email(t('invalid-email'))
      }),
      enableReinitialize: true
    })

  if (showSuccess) {
    return (
      <form onSubmit={handleSubmit}>
        <Typography centered size='lg' weight='semi'>
          {t('reset-password')}
        </Typography>

        <div className='b-spacer__2' />

        <Typography inline centered>
          {t('recover-password-text-1')}
          <Typography weight='semi'>&nbsp;{values.email}&nbsp;</Typography>
          {t('recover-password-text-2')}
        </Typography>

        <div className='b-spacer__5' />

        <Button href='/signin' size='lg' type='submit' fullWidth>
          {t('signin')}
        </Button>

        <div className='b-spacer__2' />

        <div className='b-row b-row__justify-center'>
          <button type='submit' disabled={timer > 0}>
            <Typography
              centered
              className={clsx('b-link', timer > 0 && 'b-link--disabled')}
            >
              {timer > 0 ? resendInText : t('resend-letter')}
            </Typography>
          </button>
        </div>
      </form>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <LoadingWrapper isLoading={recoverQuery.isLoading}>
        <Typography centered size='lg' weight='semi'>
          {t('recover-account')}
        </Typography>

        <div className='b-spacer__2' />

        <Typography centered size='md'>
          {t('recover-account-text')}
        </Typography>

        <div className='b-spacer__4' />

        <TextField
          size='lg'
          name='email'
          label={t('email')}
          value={values.email}
          error={touched.email && errors.email}
          postfix={<img src={iconUser} alt='' />}
          placeholder={t('email')}
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__5' />

        <Button size='lg' type='submit' fullWidth>
          {t('recover')}
        </Button>

        <div className='b-spacer__2' />

        <Typography centered>
          <Link to='/signin' className='b-link'>
            {t('return-to-signin')}
          </Link>
        </Typography>
      </LoadingWrapper>
    </form>
  )
}

export default PasswordRecovery
