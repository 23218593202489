import { t } from 'utils/localization'
import { TFileType } from 'utils/types'

import Typography from 'components/Typography'
import iconLink from 'assets/svg/link.svg'
import iconImage from 'assets/svg/image.svg'
import iconVideo from 'assets/svg/video-square.svg'
import iconModel from 'assets/svg/3d-rotate.svg'
import iconDocument from 'assets/svg/document-text.svg'

const DOCUMENT_TYPES: {
  [key in TFileType]: {
    note: string
    icon: string
    title: string
  }
} = {
  image: {
    note: 'images-types',
    icon: iconImage,
    title: 'images'
  },
  document: {
    note: 'documents-types',
    icon: iconDocument,
    title: 'documents'
  },
  model: {
    note: '3d-models-types',
    icon: iconModel,
    title: '3d-models'
  },
  link: {
    note: 'https://',
    icon: iconLink,
    title: 'links'
  },
  video: {
    note: 'video-types',
    icon: iconVideo,
    title: 'videos'
  }
}

interface DocumentTypeProps {
  type: TFileType
}

const DocumentType: React.FC<DocumentTypeProps> = (props) => {
  const { type } = props

  return (
    <div className='b-file-type'>
      <div className='b-file-type__icon'>
        <img src={DOCUMENT_TYPES[type].icon} alt='' />
      </div>
      <div className='b-file-type__inner'>
        <Typography size='xs' weight='semi'>
          {t(DOCUMENT_TYPES[type].title)}
        </Typography>
        <Typography size='xs' weight='medium' color='grey'>
          {t(DOCUMENT_TYPES[type].note)}
        </Typography>
      </div>
    </div>
  )
}

export default DocumentType
