import imageWhiteboard from 'assets/images/help_web_whiteboard.jpg'
import imageScreen from "assets/images/help_web_screen.jpg"
import imageCreateRoom from "assets/images/help_web_create_room.jpg"
import imageUploadFile from "assets/images/help_web_upload_files.jpg"


import image3dPen from 'assets/images/help_vr_3dpen.jpg'
import imageAudioplayer from "assets/images/help_vr_audioplayer.jpg"
import imageAvatar from "assets/images/help_vr_avatar.jpg"
import imageBrowser from "assets/images/help_vr_browser.jpg"
import imagePromter from 'assets/images/help_vr_promter.jpg'
import imageSearchFile from "assets/images/help_vr_searchfile.jpg"
import imageUploadFileVR from "assets/images/help_vr_upload_file.jpg"
import imageVirtualCamera from "assets/images/help_vr_virtual_camera.jpg"

export const VR_PLATFORM_ITEMS = [{
        id: "UploadFile",
        thumb:imageUploadFileVR,
        url: "https://youtu.be/V2VJ39U4fW0?si=MmU9HBARDRFDi7Ge",
        vid: "V2VJ39U4fW0",
        title: "Upload File"
    },{
        id: "SearchFile",
        thumb:imageSearchFile,
        url: "https://youtu.be/ifEK_70uRko?si=Va_v1FqXwhDIMDna",
        vid: "ifEK_70uRko",
        title: "Search File"
    },{
        id: "Avatar",
        thumb:imageAvatar,
        url: "https://youtu.be/i9MV_5mJjh8?si=I2gmw2ZLpXwpCnW2",
        vid: "i9MV_5mJjh8",
        title: "Avatar"
    },{
        id: "3DPen",
        thumb:image3dPen,
        url: "https://youtu.be/ML4LyR5HuO0?si=vVd3bmb_nLGc8I5Q",
        vid: "ML4LyR5HuO0",
        title: "3D Pen"
    },{
        id: "Promter",
        thumb:imagePromter,
        url: "https://youtu.be/qidgDMnW5mE?si=VHbKnbE0JEhKm1n9",
        vid: "qidgDMnW5mE",
        title: "Promter"
    },{
        id: "Audioplayer",
        thumb:imageAudioplayer,
        url: "https://www.youtube.com/watch?v=Kkf4hUme3ow",
        vid: "Kkf4hUme3ow",
        title: "Audioplayer"
    },{
        id: "Browser",
        thumb:imageBrowser,
        url: "https://www.youtube.com/watch?si=9du8mPkvJaZmsGv_&v=FkPsA37RgRQ&feature=youtu.be&ab_channel=BeyondReal",
        vid: "FkPsA37RgRQ",
        title: "Browser"
    },{
        id: "VirtualCamera",
        thumb:imageVirtualCamera,
        url: "https://www.youtube.com/watch?v=RRyk_m0unF4",
        vid: "RRyk_m0unF4",
        title: "Virtual Camera"
    }];

export const WEB_PLATFORM_ITEMS = [{
        id: "CreateRoom",
        thumb:imageCreateRoom,
        url: "https://youtu.be/KhJL-sCd2_0",
        vid: "KhJL-sCd2_0",
        title: "Create Room"
    },{
        id: "Whiteboard",
        thumb: imageWhiteboard,
        url: "https://youtu.be/MwHFDKkUPRk",
        vid: "MwHFDKkUPRk",
        title: "Whiteboard"
    },{
        id: "Screen",
        thumb:imageScreen,
        url: "https://youtu.be/HKxg54PPRGc",
        vid: "HKxg54PPRGc",
        title: "Screen"
    },{
        id: "UploadFile",
        thumb:imageUploadFile,
        url: "https://youtu.be/L8lcVc8g1cU",
        vid: "L8lcVc8g1cU",
        title: "Upload File"
    }];