import * as yup from 'yup'
import Button from 'components/Button'
import useRequest from 'utils/hooks/useRequest'
import Typography from 'components/Typography'
import PasswordField from 'components/PasswordField'
import LoadingWrapper from 'components/LoadingWrapper'
import { t } from 'utils/localization'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface IResetForm {
  token: string
  password: string
  passwordVerify: string
}

const PasswordReset = () => {
  const params = useParams<{ token: string }>()
  const navigate = useNavigate()

  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  useEffect(() => {
    setShowSuccess(params.token === 'success')
  }, [params.token])

  const resetQuery = useRequest('/password-reset', {
    method: 'POST',
    onSuccess() {
      navigate('/change-password/success')
    }
  })

  const handleReset = (values: IResetForm) => {
    resetQuery.request(values)
  }

  const { values, errors, touched, handleChange, handleSubmit } =
    useFormik<IResetForm>({
      onSubmit: (values) => handleReset(values),
      initialValues: {
        token: params.token || '',
        password: '',
        passwordVerify: ''
      },
      validationSchema: yup.object({
        password: yup
          .string()
          .required(t('required'))
          .min(8, t('password-invalid')),
        passwordVerify: yup
          .string()
          .test(
            'check-password-confirm',
            t('verify-password-invalid'),
            (value, { parent }) => !!value && value === parent.password
          )
      })
    })

  if (showSuccess) {
    return (
      <form>
        <Typography centered size='lg' weight='semi'>
          {t('password-updated')}
        </Typography>

        <div className='b-spacer__2' />

        <Typography centered size='md'>
          {t('password-updated-text')}
        </Typography>

        <div className='b-spacer__5' />

        <Button href='/signin' size='lg' fullWidth>
          {t('signin')}
        </Button>
      </form>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <LoadingWrapper isLoading={resetQuery.isLoading}>
        <Typography centered size='lg' weight='semi'>
          {t('reset-password')}
        </Typography>

        <div className='b-spacer__4' />

        <PasswordField
          size='lg'
          name='password'
          value={values.password}
          error={touched.password && errors.password}
          label={t('new-password')}
          placeholder={t('password')}
          hideRecovery
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__2' />

        <PasswordField
          size='lg'
          name='passwordVerify'
          value={values.passwordVerify}
          error={touched.passwordVerify && errors.passwordVerify}
          placeholder={t('repeat-password')}
          hideRecovery
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__5' />

        <Button size='lg' type='submit' fullWidth>
          {t('send')}
        </Button>
      </LoadingWrapper>
    </form>
  )
}

export default PasswordReset
