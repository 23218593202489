import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { useState } from 'react'
import LocaleService, { t } from 'utils/localization'
import HelpItem from './HelpItem'
import { VR_PLATFORM_ITEMS } from './items'

const VrPlatformTab = () => {
    const list = VR_PLATFORM_ITEMS;
    const [open, setOpen] = useState(false);
    const [document, setDocument] = useState(list[0]);
    const openVideo = (doc:any) => {
        setDocument(doc);
        setOpen(true);
    }
    return (
        <div>
            <Typography weight='semi' className='text-sm-center'>{t('vr-platform')}</Typography>
            <div className='b-spacer__3' />
            <div className='b-row b-row-mobile-centered'>
                {list.map((doc, index) => (
                    <HelpItem
                        key={doc.id}
                        documentThumb={doc.thumb}
                        title={doc.title}
                        onSelect={() => openVideo(doc)}
                    />
                ))}
            </div>
            <div className='b-row b-row-mobile-centered'>
            </div>
            <Modal
                isOpen={open}
                onClose={() => setOpen(false)}
                className='b-document-modal'
                clickOutsideOptions={{
                    ignoreClassNames: ['b-document-modal__icon']
                }}
                topElement={
                    <div className='b-document-modal__header'>
                    </div>
                }
                >
                <div className='b-document-modal__content'>
                    {open && <iframe
                        src={`https://www.youtube.com/embed/${document.vid}?hl=${LocaleService.locale}`}
                        title={document.title}
                        width='100%'
                        height='100%'
                        frameBorder='0'
                        allowFullScreen
                    />}
                </div>
            </Modal>
        </div>
  )
}

export default VrPlatformTab