import { t } from 'utils/localization'
import { parseWord } from 'utils/helpers'
import { useState, useEffect, useMemo } from 'react'

const useTimer = (initialValue?: number) => {
  const [timer, setTimer] = useState<number>(initialValue || 0)

  useEffect(() => {
    let interval: NodeJS.Timer | null = null

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1)
      }, 1000)
    } else if (interval) {
      clearInterval(interval)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const resendInText = useMemo(() => {
    if (timer <= 0) {
      return ''
    }

    const seconds = parseWord(
      timer,
      ['секунд', 'секунду', 'секунды'],
      'seconds'
    )

    return `${t('resend-in')} ${seconds}`
  }, [timer])

  return { timer, setTimer, resendInText }
}

export default useTimer
