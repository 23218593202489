//@ts-ignore
import MoleServer from 'mole-rpc/MoleServer'
//@ts-ignore
import MoleClient from 'mole-rpc/MoleClientProxified'
//@ts-ignore
import TransportClientWS from 'mole-rpc-transport-ws/TransportClientWS'
//@ts-ignore
import TransportServerWS from 'mole-rpc-transport-ws/TransportServerWS'

let ws: WebSocket = new WebSocket(process.env.REACT_APP_CLOUD_HOST || '')

export const client = new MoleClient({
  transport: new TransportClientWS({
    wsBuilder: () => ws,
    ping: true,
    pingInterval: 60000
  })
})

export default client

const moleServer = new MoleServer({
  transports: [
    new TransportServerWS({
      wsBuilder: () => ws
    })
  ]
})

export const server = {
  on(method: string, handler: Function) {
    moleServer.methods[method] = function (...args: any) {
      handler(...args)
    }
  }
}

moleServer.run()
