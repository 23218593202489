import client from './socket'
import ApiService from './api'
import { t } from './localization'
import { alerts } from 'components/Toast/Toast'
import { getDocumentType, getUserIdFromToken } from './helpers'
import { IReservedDocument, TDocumentType, TFileType } from './types'

export type RichDocument = RichUrl | RichFile

export type TFileStatus = 'done' | 'failed' | 'loading'

export class RichInterface {
  mime: string = ''
  type?: TFileType
  userId?: string
  docType?: TDocumentType
  uploadedId?: string
  uploadStatus: TFileStatus = 'loading'

  async upload(callback: () => Promise<string>) {
    try {
      this.uploadStatus = 'loading'

      const userId = getUserIdFromToken()

      if (!userId) {
        throw new Error('No file or userId')
      }

      this.userId = userId

      const uploadedId = await callback()

      this.uploadStatus = 'done'
      this.uploadedId = uploadedId

      return uploadedId
    } catch (error) {
      console.error({ error })

      this.uploadStatus = 'failed'
      if ((error as any)?.data === 'limit reached') {
        alerts.error(t('doc-limit-reached'))
      }
      return ''
    }
  }
}

export class RichUrl extends RichInterface {
  url: string
  siteData?: {
    title: string
    image: string
  }

  constructor(url: string) {
    super()
    this.url = url
    this.init()
  }

  async init() {
    this.initMime()
    this.initTypes()
  }

  initMime() {
    this.mime = 'text/html'
  }

  initTypes() {
    const { url } = this

    if (
      /^https?:\/\/(?:(?:www\.)?youtube\.com\/watch\?v=|youtu\.be\/)/.test(url)
    ) {
      this.type = 'video'
      this.docType = 'video'
    }
    if (/^(?!https?:\/\/(?:www\.)?(?:youtu\.be|youtube\.com))/.test(url)) {
      this.type = 'link'
      this.docType = 'link'
    }
  }

  async loadSiteData() {
    const { url } = this

    const res = await ApiService.get<{ title: string; image: string }>(
      '/site-data/?url=' + encodeURIComponent(url)
    )

    if (res.data) {
      this.siteData = res.data
    }
  }

  async uploadFile() {
    this.uploadStatus = 'loading'

    await this.loadSiteData()

    return this.upload(async () => {
      const { url, mime, userId, siteData } = this

      return await client.upsertDoc({
        url,
        mime,
        scope: userId,
        title: siteData?.title,
        thumb: await getImagePreview(siteData?.image)
      })
    })
  }
}

export class RichFile extends RichInterface {
  file: File
  isZip: boolean = false
  isModel: boolean = false

  constructor(file: File) {
    super()
    this.file = file
    this.init()
  }

  init() {
    this.initMime()
    this.initTypes()
  }

  initMime() {
    const { file } = this
    this.mime = file.type || this.getMimeTypeFromFile(file)
  }

  initTypes() {
    const { mime } = this

    const typeData = getDocumentType(mime)
    if (typeData) {
      this.type = typeData.type
      this.docType = typeData.docType
      this.isModel = typeData.isModel ?? false
      this.isZip = typeData.isZip ?? false
    }
  }

  async uploadFile() {
    return this.upload(async () => {
      const { file, isZip, userId, isModel, docType } = this

      if (docType === 'text') {
        return await client.upsertDoc({
          text: await this.readFileAsText(),
          mime: 'text/plain',
          scope: userId,
          title: file.name
        })
      }

      const reservedDoc: IReservedDocument = await client.reserveDoc(userId)

      if (!reservedDoc?.docId) {
        throw new Error("docId wasn't generated")
      }

      let fileToSave = file

      if (isModel || isZip) {
        fileToSave = await this.convertToGlb()
      }

      const uploadUrl = `${process.env.REACT_APP_CDN_HOST}/ds/${reservedDoc.docId}`

      const savedDocument = await fetch(uploadUrl, {
        body: fileToSave,
        method: 'POST',
        headers: { Authorization: `Bearer ${reservedDoc.token}` }
      })

      if (!savedDocument.ok) {
        throw new Error('Error saving file')
      }

      const docUrl = docType === 'video' ? `${process.env.REACT_APP_R2_DOCS_URL}/${reservedDoc.docId}` : uploadUrl

      return await client.upsertDoc({
        _id: reservedDoc.docId,
        url: docUrl,
        mime: fileToSave.type || this.getMimeTypeFromFile(fileToSave),
        scope: userId,
        title: fileToSave.name,
        thumb: await this.getThumb(reservedDoc.docId, docUrl)
      })
    })
  }

  async convertToGlb() {
    const { file, isZip } = this

    let fileType = 'glb'

    const mime = file.type || this.getMimeTypeFromFile(file)

    if (/^model\/obj$/.test(mime)) {
      fileType = 'obj'
    } else if (/^model\/fbx$/.test(mime)) {
      fileType = 'fbx'
    } else if (isZip) {
      fileType = 'zip'
    } else {
      return file
    }

    const convertedModel = await fetch(
      `${process.env.REACT_APP_CONVERTER_HOST}/convert/${fileType}`,
      { body: file, method: 'POST' }
    )
    if (!convertedModel.ok) {
      throw new Error('Error saving file')
    }

    const convertedModelFile = await convertedModel.blob()

    return new File([convertedModelFile], `${file.name}.glb`, {
      type: 'model/gltf-binary'
    })
  }

  async readFileAsText() {
    const { file } = this

    return new Promise<string>((resolve) => {
      const reader = new FileReader()

      reader.addEventListener('load', function (e) {
        resolve((e.target?.result || '') as string)
      })

      reader.readAsBinaryString(file)
    })
  }

  async getThumb(docId: string, docUrl: string) {
    const { isZip, isModel, docType } = this

    return docType === 'image'
      ? await getImagePreview(docUrl)
      : isZip || isModel
      ? getModelPreview(docId)
      : undefined
  }

  getMimeTypeFromFile(file: File) {
    const MIME_TYPE: { [key: string]: string } = {
      obj: 'model/obj',
      fbx: 'model/fbx',
      glb: 'model/gltf-binary',
      gltf: 'model/gltf-binary'
    }

    const ext = file.name.split('.').pop() || ''
    return MIME_TYPE[ext.toLocaleLowerCase()] || ''
  }
}

const getImagePreview = async (imageUrl?: string) => {
  if (!imageUrl) {
    return ''
  }

  try {
    const docPreview = await fetch(
      `${process.env.REACT_APP_CDN_HOST}/alpha/lookup?url=${imageUrl}`,
      { method: 'GET' }
    )

    if (!docPreview.ok) {
      console.info('Doc preview request info', docPreview)
      throw new Error('Error getting doc preview')
    }

    return docPreview.url
  } catch (error) {
    console.error('Error getting doc preview', error)
    return ''
  }
}

const getModelPreview = async (docId: string) => {
  return `${process.env.REACT_APP_CONVERTER_HOST}/getThumb/${docId}`
}

export const getModelWithTextures = async (files: RichFile[]) => {
  try {
    let modelFile
    let modelsCount = 0
    let imagesCount = 0
    let othersCount = 0

    for (const richFile of files) {
      if (richFile.isModel) {
        modelsCount++
        modelFile = richFile.file
      } else if (
        /^image\/png$|^image\/jpg$|^image\/jpeg$/.test(richFile.mime)
      ) {
        imagesCount++
      } else {
        othersCount++
      }
    }

    if (!modelsCount || modelsCount > 1 || othersCount > 0 || !imagesCount) {
      return null
    }

    const zip = require('jszip')()
    for (const richFile of files) {
      zip.file(richFile.file.name, richFile.file)
    }

    const blob: Blob = await zip.generateAsync({ type: 'blob' })
    return new File([blob], `${modelFile?.name}.zip`, {
      type: 'application/zip'
    })
  } catch {
    return null
  }
}
