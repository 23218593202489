export const gtag = (...args: any) => {
    try {
        // @ts-ignore
        window.gtag(...args);
    } catch (e) {
        console.error(e);
    }
}

export const pushDataLayer = (data: any) => {
    if (!data) {
        return console.warn('You must provide a data to push to data layer');
    }

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push(data);
}