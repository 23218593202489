import { useState } from 'react'
import Header from './components/Header'
import SideMenu from './components/SideMenu'
import './styles.scss'

const Layout: React.FC = (props) => {
  const [showSideMenu, setShowSideMenu] = useState(false)

  return (
    <div className='b-layout'>
      <SideMenu
        isMenuOpen={showSideMenu}
        onOverlayClick={() => setShowSideMenu(false)}
      />

      <div className='b-layout__container'>
        <Header onBurgerClick={() => setShowSideMenu(true)} />

        <main className='b-layout__content'>{props.children}</main>
      </div>
    </div>
  )
}

export default Layout
