import clsx from 'clsx'
import iconClose from 'assets/svg/close.svg'
import { useRef } from 'react'
import useDisableBodyScroll from 'utils/hooks/useDisableBodyScroll'
import useOnClickOutside, {
  TUseOnClickOutsideOptions
} from 'utils/hooks/useOnClickOutside'

import './styles.scss'

interface ModalProps {
  id?: string
  isOpen: boolean
  onClose?(): void
  className?: string
  hideClose?: boolean
  scrollable?: boolean
  topElement?: React.ReactNode
  clickOutsideOptions?: TUseOnClickOutsideOptions
}

const Modal: React.FC<ModalProps> = (props) => {
  const {
    id,
    isOpen,
    onClose,
    children,
    className,
    hideClose,
    scrollable,
    topElement,
    clickOutsideOptions,
    ...rest
  } = props

  const ref = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    onClose && onClose()
  }

  useDisableBodyScroll(isOpen)
  useOnClickOutside(ref, handleClose, clickOutsideOptions)

  return (
    <div
      id={id}
      className={clsx(
        'b-modal',
        isOpen && 'b-modal--open',
        scrollable && 'b-modal--scrollable'
      )}
    >
      {topElement}
      <div
        {...rest}
        ref={ref}
        className={clsx('b-modal__container', className)}
      >
        <div className='b-modal__content'>{children}</div>

        {!!onClose && !hideClose && (
          <button type='button' onClick={onClose} className='b-modal__close'>
            <img src={iconClose} alt='' />
          </button>
        )}
      </div>
    </div>
  )
}

export default Modal
