import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import TextField from 'components/TextField/TextField'
import Typography from 'components/Typography/Typography'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { useEffect, useState } from 'react'
import iconPlus from 'assets/svg/plus-rooms.svg'
import iconMinus from 'assets/svg/minus.svg'
import iconWarning from 'assets/svg/warning.svg'
import './styles.scss';
import { PRICE_PER_ROOM } from 'utils/constants'
import { formatPrice } from 'utils/helpers'

const UnsubscribeRoomsModal = () => {
    const profile = useProfile()
    
    const [showWarn, setShowWarn] = useState(false)
    const [numberOfRooms, setNumberOfRooms] = useState(0);
    const [isLoading, setLoading] = useState(false);
  
    useEffect(() => {
        if (!profile.showUnsubscribeRoomsModal) {
            setTimeout(() => {
                setNumberOfRooms(0)
                setLoading(false);
            }, 500)
        }
    }, [profile, profile.showUnsubscribeRoomsModal])

    useEffect(() => {
        if (profile.user) {
            setNumberOfRooms(profile.user.cloudData.slotsRenew);
        }
    }, [profile])

    if (!profile.user) {
        return null;
    }
    const slotsPaid = profile.user.cloudData.slotsPaid;
    const slotUsed = profile.user.cloudData.slotsUsed;
    const slotsRenew = profile.user.cloudData.slotsRenew;
    

    const decreaseRooms = () => {
        const newQnt = (numberOfRooms-1) < 0 ? 0 : numberOfRooms-1;
        setNumberOfRooms(newQnt);
        if (newQnt < slotUsed) {
            setShowWarn(true);
        }
    }

    const increaseRooms = () => {
        const newQnt = (numberOfRooms+1) > slotsRenew ? slotsRenew : numberOfRooms+1 ;
        setNumberOfRooms(newQnt);
        if (newQnt < slotUsed) {
            setShowWarn(true);
        } else {
            setShowWarn(false);
        }
    }

    const confirm = () => {
        profile.setSubscriptionSlots(numberOfRooms, 'remove')
        setLoading(true);
    }

    return (
        <Modal
        isOpen={profile.showUnsubscribeRoomsModal}
        onClose={() => profile.setShowUnsubscribeRoomsModal(false)}
        hideClose
        className='b-payment-modal'
        >
            <Typography as='h1' centered>
                {t('cancel-room-subscription')}
            </Typography>

            <div className='b-spacer__2' />

            <Typography size='md' centered>
                {t('select-subscription-next-month')}
            </Typography>

            <div className='b-spacer__6' />
            
            <TextField
                size='lg'
                name='activeRooms'
                label={t('active-room-subscription')}
                value={`${slotsPaid} (${t('used')}: ${slotUsed})`}
                disabled
            />

            <div className='b-spacer__2' />

            <TextField
                size='lg'
                name='ongoingRooms'
                label={t('ongoing-room-subscription')}
                value={`${numberOfRooms}`}
                disabled
            />
            <div className='b-spacer__2' />
            <div className='b-balance__modal__controls'>
                <Button variant='shadow' onClick={decreaseRooms}>
                <img src={iconMinus} alt='Decrease rooms subscription' />  
                </Button>
                <Button variant='shadow' onClick={increaseRooms}>
                <img src={iconPlus} alt='Add rooms subsription' />
                </Button>
            </div>

            <div className='b-spacer__2' />
            {showWarn ? <div className='b-balance__warning'>
                <img alt='warning' src={iconWarning} />
                <Typography size='xs' centered>
                    {t('rooms-unsubscr-warning')} {slotUsed - numberOfRooms} {t('room')}
                </Typography>
                
            </div> : ''}
            
            <div className='b-spacer__2' />

            <TextField
                size='lg'
                name='priceMonthly'
                label={
                <div className='b-row'>
                    <Typography>{t('total-subscription-price')}&nbsp;</Typography>
                    <Typography size='md' color='grey'>({t('subscription-price-note')})</Typography>
                </div>
                }
                value={formatPrice(numberOfRooms * PRICE_PER_ROOM)}
                disabled
            />

            <div className='b-spacer__6' />

            <div className='b-payment-modal__footer'>
                <Button
                size='lg'
                variant='shadow'
                onClick={() => profile.setShowUnsubscribeRoomsModal(false)}
                >
                {t('cancel')}
                </Button>

                <Button
                size='lg'
                onClick={confirm}
                disabled={(slotsRenew === numberOfRooms) || isLoading}
                >
                {t('confirm')}
                </Button>
            </div>

            <div className='b-spacer__6' />

            <Typography size='md' color='grey' centered>
                {t('subscription-terms')}

                <div className='b-spacer__1' />
                <div>
                <a
                    rel='noreferrer'
                    href='https://beyondreal.life/pricing/'
                    target='_blank'
                    className='b-link'
                >
                    https://beyondreal.life/pricing/
                </a>
                </div>
            </Typography>
        </Modal>
    )
}

export default UnsubscribeRoomsModal
