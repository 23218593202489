import * as yup from 'yup'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import TextField from 'components/TextField/TextField'
import Typography from 'components/Typography/Typography'
import PriceTextField from 'components/PriceTextField'
import { FALLBACK_LOCALE, t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { formatPrice } from 'utils/helpers'
import { PRICE_PER_ROOM } from 'utils/constants'
import { useSearchParams } from 'react-router-dom'
import {pushDataLayer} from "../../utils/gtm";

interface IBalanceForm {
  amount: number
}

const BalanceModal = () => {
  
  const origin = process.env.REACT_APP_ORIGIN;
  const profile = useProfile()
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);


  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const success = searchParams.get("success")
    if (success === 'true') {
      const purchaseRoomsonRefill = localStorage.getItem('purchaseRoomsonRefill');
      if (purchaseRoomsonRefill && (Number(purchaseRoomsonRefill) > 0) && profile.user) {
        const roomsTotdal = Number(purchaseRoomsonRefill)+profile.user?.cloudData.slotsRenew;
        profile.setSubscriptionSlots(roomsTotdal, 'add')
        setLoading(true);
        setShowFailure(false);
        setShowSuccess(true);
        profile.setShowRefillBalanceModal(true);
        setSearchParams('')
      } else {
        setShowFailure(false);
        setShowSuccess(true);
        profile.setShowRefillBalanceModal(true);
        setSearchParams('')
      }
      if (purchaseRoomsonRefill) {
        localStorage.removeItem('purchaseRoomsonRefill');
      }
    }
    if (success === 'false') {
      setShowFailure(true);
      setShowSuccess(false);
      profile.setShowRefillBalanceModal(true);
      setSearchParams('')
    }
  }, [profile, searchParams])

  const handleRefill = (values: IBalanceForm) => {
    pushDataLayer({
      event: "checkout",
      category: "Users",
      user_id: profile.user?.authData.id,
      origin: window.location.origin,
    })
    setLoading(true)
    const data = {
      email: profile.user?.authData.email,
      amount: values.amount * 100,
      success_url: `${origin}/profile?success=true`,
      cancel_url: `${origin}/profile?success=false`
    }
    try {
      const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.getItem('locale') || FALLBACK_LOCALE 
      }
      fetch(`${process.env.REACT_APP_API_HOST}/create-checkout-session`, {
        method: "POST", 
        headers: headers,
        redirect: "follow", 
        body: JSON.stringify(data), 
      }).then( response => {
        if (response.ok) {
          return response.text()
        } else {
          console.log('response not ok', response);
        }
      }).then(url => {
        setLoading(false)
        if (url) {
          window.location.href = url;
        }
      });
    } catch (e) {
      console.log('error init checkout', e);
      setLoading(false)
    }
  
  }

  const { values, errors, touched, resetForm, handleSubmit, setFieldValue } =
    useFormik<IBalanceForm>({
      onSubmit: (values) => handleRefill(values),
      initialValues: { amount: 0 },
      validationSchema: yup.object({
        amount: yup.number().required(t('required')).min(1, t('required'))
      })
    })

  useEffect(() => {
    if (!profile.showRefillBalanceModal) {
      setTimeout(() => {
        resetForm()
      }, 500)
    }
  }, [resetForm, profile.showRefillBalanceModal])

  if (!profile.user) {
    return null;
  }
  const dueNextMonth = profile.user.cloudData.slotsPaid * PRICE_PER_ROOM;

  const complete = ()=> {
    setLoading(false);
    profile.setShowRefillBalanceModal(false);
    setTimeout(() => {
      setShowSuccess(false);
      setShowFailure(false);
    }, 500)
    
  }

  return (
    <Modal
      isOpen={profile.showRefillBalanceModal}
      onClose={() => profile.setShowRefillBalanceModal(false)}
      hideClose
      className='b-payment-modal'
    >
      {showSuccess ? 
        <>
          <Typography as='h1' centered>
              {t('payment-accepted')}
          </Typography>

          <div className='b-spacer__2' />

          <Typography size='md' centered>
              {t('congratulations-replenishing')}
          </Typography>

          <div className='b-spacer__6' />
          <Button
              size='lg'
              onClick={complete}
              fullWidth
          >
              {t('confirm')}
          </Button>
        </>
      : ''}
      {showFailure ? 
        <>
          <Typography as='h1' centered>
              {t('payment-declined')}
          </Typography>

          <div className='b-spacer__2' />

          <Typography size='md' centered>
              {t('payment-rejeted-try-again')}
          </Typography>

          <div className='b-spacer__6' />
          <Button
              size='lg'
              onClick={complete}
              fullWidth
          >
              {t('confirm')}
          </Button>
        </>
      : ''}
      {!showSuccess && !showFailure ? <form onSubmit={handleSubmit}>
        <Typography as='h1' centered>
          {t('refill-balance')}
        </Typography>

        <div className='b-spacer__2' />

        <Typography size='md' centered>
          {t('enter-balance-amount')}
        </Typography>

        <div className='b-spacer__6' />

        <TextField
          size='lg'
          name='email'
          label={t('email')}
          value={profile.user.authData.email}
          disabled
        />

        <div className='b-spacer__2' />

        <TextField
          size='lg'
          name='currentBalance'
          label={t('current-balance')}
          value={formatPrice(profile.user.cloudData.balance/100)}
          disabled
        />

        <div className='b-spacer__2' />

        <TextField
          size='lg'
          name='due'
          label={t('due-next-month')}
          value={formatPrice(dueNextMonth)}
          disabled
        />

        <div className='b-spacer__2' />

        <PriceTextField
          size='lg'
          name='amount'
          value={values.amount}
          label={t('amount')}
          error={touched.amount && errors.amount}
          onChange={(value) => setFieldValue('amount', value)}
        />

        <div className='b-spacer__6' />

        <div className='b-payment-modal__footer'>
          <Button
            size='lg'
            variant='shadow'
            onClick={() => profile.setShowRefillBalanceModal(false)}
          >
            {t('cancel')}
          </Button>

          <Button size='lg' type='submit' disabled={loading || (values.amount === 0)}>
            {t('pay-with-stripe')}
          </Button>
        </div>
      </form> : ''}
    </Modal>
  )
}

export default BalanceModal
