import clsx from 'clsx'
import ErrorLabel from 'components/ErrorLabel'
import iconWarning from 'assets/svg/warning.svg'
import { IInputProps } from 'utils/types'
import { useState, ChangeEvent } from 'react'
import './styles.scss'

export interface TextFieldProps extends IInputProps {
  type?: 'text' | 'password' | 'textarea'
  size?: 'md' | 'lg'
  value?: string
  onChange?(value: string): void
  onChangeFormik?(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void
  onBlur?(): void
  onFocus?(): void
}

const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    type = 'text',
    size = 'md',
    name,
    note,
    error,
    label,
    value,
    prefix,
    postfix,
    readOnly,
    onChange,
    disabled,
    inputRef,
    className,
    placeholder,
    onChangeFormik,
    onBlur,
    onFocus
  } = props

  const [isFocused, setIsFocused] = useState<boolean>(false)

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const val = event.target.value
    onChange && onChange(val)
    onChangeFormik && onChangeFormik(event)
  }
  const handleBlur = () => {
    setIsFocused(false)
    onBlur && onBlur();
  }
  const handleFocus = () => {
    setIsFocused(true)
    onFocus && onFocus();
  }

  const inputProps = {
    id: name || '',
    name: name || '',
    value: value,
    onBlur: handleBlur,
    onFocus: handleFocus,
    disabled: disabled,
    readOnly: readOnly,
    onChange: handleChange,
    className: 'b-textfield__input',
    placeholder: placeholder
  }

  return (
    <>
      {!!label && (
        <label htmlFor={name} className='b-textfield__label'>
          {label}
        </label>
      )}
      <div
        className={clsx(
          'b-textfield',
          `b-textfield--${size}`,
          error && 'b-textfield--error',
          disabled && 'b-textfield--disabled',
          isFocused && 'b-textfield--focused',
          className
        )}
      >
        {!!prefix && <div className='b-textfield__prefix'>{prefix}</div>}

        {type === 'textarea' ? (
          <textarea {...inputProps} />
        ) : (
          <input ref={inputRef} type={type} {...inputProps} />
        )}

        {!!postfix && !error && (
          <div className='b-textfield__postfix'>{postfix}</div>
        )}

        {!!error && (
          <img className='b-textfield__postfix' alt='' src={iconWarning} />
        )}
      </div>

      {note && <div className='b-note'>{note}</div>}
      {error && <ErrorLabel message={error as string} />}
    </>
  )
}

export default TextField
