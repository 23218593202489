import * as yup from 'yup'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import iconUser from 'assets/svg/user.svg'
import iconEmail from 'assets/svg/email.svg'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import useRequest from 'utils/hooks/useRequest'
import PasswordField from 'components/PasswordField'
import LoadingWrapper from 'components/LoadingWrapper'
import AnalyticsService from 'utils/analytics'
import LocaleService, { t } from 'utils/localization'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useProfile } from 'utils/hooks/useContext'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { pushDataLayer } from "../../utils/gtm";
import iconLogo from 'assets/images/Logo_black_2.png'

const TERMS_OF_USE_URL = {
  ru: 'http://beyondreal.life/terms-and-conditions/',
  en: 'http://beyondreal.life/terms-and-conditions/'
}

const PRIVACY_POLICY_URL = {
  ru: 'https://beyondreal.life/privacy-policy/',
  en: 'https://beyondreal.life/privacy-policy/'
}

interface ISignupForm {
  email: string
  login: string
  password: string
  confirmation: boolean
  // passwordVerify: string
}

const SingupPage = () => {
  const profile = useProfile()
  const location = useLocation()
  const navigate = useNavigate()

  const signupQuery = useRequest('/register', {
    method: 'POST',
    onSuccess(data) {
      pushDataLayer({
        event: 'sign_up',
        category: "Users",
        origin: window.location.origin,
      }); // legacy events

      pushDataLayer({
        event: 'signUpSuccessful'
      });
      AnalyticsService.track('Signup')
      profile.login(data.token, () => {
        profile.setFirstTimeReg(true)
        // profile.setShowDownload(true)
        // profile.setShowEmailConfirmation(true)
        // navigate('/')
        const state = location.state as { subscribe?: boolean; }
        console.log('state', state);
        if (state && state.subscribe) {
          // navigate('/profile', {state: {tab: 'balance', modal: 'subscription' }});
          window.location.replace(process.env.REACT_APP_SUBSCRIPTION_REDIRECT || '')
        } else {
          window.location.href = 'https://app.beyondreal.life/';  
        }
        
      })
    }
  })

  const handleSignin = (values: ISignupForm) => {
    signupQuery.request({...values, passwordVerify: values.password, regpoint: 'Website'})
  }

  const { values, errors, setFieldValue, touched, handleChange, handleSubmit } =
    useFormik<ISignupForm>({
      onSubmit: (values) => handleSignin(values),
      initialValues: {
        email: '',
        login: '',
        password: '',
        confirmation: true,
        // passwordVerify: ''
      },
      validationSchema: yup.object({
        email: yup.string().required(t('required')).email(t('invalid-email')),
        login: yup.string().required(t('required')),
        password: yup
          .string()
          .required(t('required'))
          .min(8, t('password-invalid')),
        confirmation: yup.boolean(),
        // passwordVerify: yup
          // .string()
          // .test(
            // 'check-password-confirm',
            // t('verify-password-invalid'),
            // (value, { parent }) => !!value && value === parent.password
          // )
      })
    })

  useEffect(() => {
    if (location.state) {
      const state = location.state as { email?: string; password?: string }
      setFieldValue('email', state.email || '')
      setFieldValue('password', state.password || '')
    }
  }, [location, setFieldValue])

  useEffect(() => {
    pushDataLayer({
      event: 'signUpStarted',
    });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <LoadingWrapper isLoading={signupQuery.isLoading}>
        <div className='b-signin__logo no-auth-logo'>
          <img src={iconLogo} alt='BEYONDREAL' />
        </div>
        <Typography centered size='lg' weight='semi'>
          {t('signup-to-inreal')}
        </Typography>

        <div className='b-spacer__4' />

        <TextField
          size='lg'
          name='login'
          value={values.login}
          error={touched.login && errors.login}
          label={t('login-and-email')}
          postfix={<img src={iconUser} alt='' />}
          placeholder={t('login')}
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__2' />

        <TextField
          size='lg'
          name='email'
          value={values.email}
          error={touched.email && errors.email}
          postfix={<img src={iconEmail} alt='' />}
          placeholder={t('email')}
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__3' />

        <PasswordField
          size='lg'
          name='password'
          value={values.password}
          error={touched.password && errors.password}
          label={t('password')}
          placeholder={t('password')}
          hideRecovery
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__2' />

        {/* <PasswordField
          size='lg'
          name='passwordVerify'
          value={values.passwordVerify}
          error={touched.passwordVerify && errors.passwordVerify}
          placeholder={t('repeat-password')}
          hideRecovery
          onChangeFormik={handleChange}
        /> */}

        <div className='b-spacer__2' />

        <Checkbox
          id='confirmation'
          checked={values.confirmation}
          onChangeFormik={handleChange}
          label={
            <div>
              {t('i-read-policy')}{' '}
              <a
                rel='noreferrer'
                href={TERMS_OF_USE_URL[LocaleService.locale]}
                target='_blank'
                className='b-link'
              >
                {t('terms-of-use')}
              </a>{' '}
              {t('and')}{' '}
              <a
                rel='noreferrer'
                href={PRIVACY_POLICY_URL[LocaleService.locale]}
                target='_blank'
                className='b-link'
              >
                {t('privacy-policy')}
              </a>
            </div>
          }
        />

        <div className='b-spacer__5' />

        <Button
          size='lg'
          type='submit'
          fullWidth
          disabled={!values.confirmation}
        >
          {t('create')}
        </Button>

        <div className='b-spacer__3' />

        <Typography centered>
          {t('have-account')}{' '}
          <Link to='/signin' className='b-link'>
            {t('signin')}
          </Link>
        </Typography>
      </LoadingWrapper>
    </form>
  )
}

export default SingupPage
