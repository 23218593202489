import * as yup from 'yup'
import client from 'utils/socket'
import Button from 'components/Button'
import iconEdit from 'assets/svg/edit.svg'
import iconUser from 'assets/svg/user.svg'
import TextField from 'components/TextField'
import useCropper, { cropImageByUrl } from 'utils/hooks/useCropper'
import LoadingWrapper from 'components/LoadingWrapper'
import { t } from 'utils/localization'
import { alerts } from 'components/Toast/Toast'
import { useFormik } from 'formik'
import { useProfile } from 'utils/hooks/useContext'
import { getUserIdFromToken } from 'utils/helpers'
import { IDocument, IReservedDocument } from 'utils/types'
import { ChangeEvent, useMemo, useRef, useState } from 'react'

interface IProfileForm {
  login: string
}

const GeneralTab = () => {
  const profile = useProfile()
  const inputFile = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const { crop, cropper } = useCropper(
    (coords, originalImage) => {
      handleAvatarSave(originalImage, coords)
    },
    {
      viewMode: 1,
      aspectRatio: 1
    }
  )

  const { login, postfix } = useMemo(() => {
    let login = ''
    let postfix = ''

    if (profile.user) {
      const parts = profile.user.cloudData.tag.split('#')
      postfix = '#' + parts.pop() || ''
      login = parts.join('')
    }

    return { login, postfix }
  }, [profile.user])

  const handleAvatarSave = async (file: File, coords: string) => {
    try {
      setLoading(true)

      const userId = getUserIdFromToken()

      if (file && userId) {
        const reservedDoc: IReservedDocument = await client.reserveDoc(userId)

        if (!reservedDoc?.docId) {
          throw new Error("docId wasn't generated")
        }

        const savedDocument = await fetch(
          `${process.env.REACT_APP_CDN_HOST}/ds/${reservedDoc.docId}`,
          {
            body: file,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${reservedDoc.token}`
            }
          }
        )

        if (!savedDocument.ok) {
          throw new Error('Error saving file')
        }

        const docPreview = await fetch(
          `${process.env.REACT_APP_CDN_HOST}/alpha/lookup?url=${process.env.REACT_APP_CDN_HOST}/ds/${reservedDoc.docId}`,
          { method: 'GET' }
        )

        if (!docPreview.ok) {
          throw new Error('Error getting doc preview')
        }

        await client.upsertDoc({
          _id: reservedDoc.docId,
          url: `${process.env.REACT_APP_CDN_HOST}/ds/${reservedDoc.docId}`,
          mime: file.type,
          scope: userId,
          title: file.name,
          thumb: docPreview.url
        })

        const profilePic: IDocument = await client.selectProfilePic(
          reservedDoc.docId,
          coords
        )

        const thumb = await cropImageByUrl(profilePic, coords)

        profile.setUser((user) =>
          user
            ? {
                ...user,
                avatar: { ...profilePic, thumb: thumb }
              }
            : null
        )
      } else {
        throw new Error('No file or userId')
      }
    } catch (error) {
      console.error({ error })
      alerts.error(t('unexpected-error'))
    } finally {
      setLoading(false)
    }
  }

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = Array.from(event.target.files || [])[0]
    if (file) {
      crop(file)
    } else {
      alerts.error(t('unexpected-error'))
    }
  }

  const handleLoginChange = async (values: IProfileForm) => {
    try {
      setLoading(true)

      const newName: string = await client.changeUsername(values.login)
      profile.setUser((user) =>
        user
          ? {
              ...user,
              cloudData: {
                ...user.cloudData,
                tag: newName
              }
            }
          : null
      )
    } catch (error) {
      alerts.error(t('unexpected-error'))
    } finally {
      setLoading(false)
    }
  }

  const { dirty, values, errors, touched, handleChange, handleSubmit } =
    useFormik<IProfileForm>({
      onSubmit: (values) => handleLoginChange(values),
      initialValues: {
        login: login
      },
      validationSchema: yup.object({
        login: yup.string().required(t('required'))
      }),
      enableReinitialize: true
    })

  return (
    <form onSubmit={handleSubmit} className='b-profile-form'>
      <LoadingWrapper isLoading={loading}>
        <div className='b-avatar'>
          {profile.user?.avatar ? (
            <img
              src={profile.user.avatar.thumb}
              alt=''
              className='b-avatar__img'
            />
          ) : (
            <img src={iconUser} alt='' className='b-avatar__placeholder' />
          )}

          <Button
            onClick={() => inputFile.current?.click()}
            className='b-avatar__edit'
          >
            <img src={iconEdit} alt='' />
          </Button>

          <input
            ref={inputFile}
            type='file'
            accept='image/png,image/jpg,image/jpeg'
            hidden
            onChange={handleAvatarChange}
          />
        </div>

        <div className='b-spacer__5' />

        <TextField
          size='lg'
          name='login'
          label={t('login')}
          value={values.login}
          error={touched.login && errors.login}
          postfix={postfix}
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__4' />

        <TextField
          size='lg'
          label={t('email')}
          value={profile.user?.authData.email}
          disabled
        />

        <div className='b-spacer__5' />

        <Button size='lg' type='submit' disabled={!dirty} fullWidth>
          {t('save')}
        </Button>
      </LoadingWrapper>

      {cropper}
    </form>
  )
}

export default GeneralTab
