import Tabs from 'components/Tabs'
import GeneralTab from './components/GeneralTab'
import BalanceTab from './components/BalanceTab'
import SecurityTab from './components/SecurityTab'
import DownloadTab from './components/DownloadTab'
import { t } from 'utils/localization'
import './styles.scss'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useProfile } from 'utils/hooks/useContext'

const Profile = () => {
  const [activeTab, setActive] = useState<string>();
  const location = useLocation();
  const profile = useProfile()
  const {state} : any = location;
  useEffect(()=> {
    console.log('state', state);
    if (!!state && state.tab && !activeTab) {
      setActive(state.tab);
      delete state.tab;
      window.history.replaceState({...location}, '')
    }
    if (!!state && state.modal) {
      if (state.modal === 'subscription') {
        // profile.setShowSubscriptionModal(true)
      }
      delete state.modal;
      window.history.replaceState({...location}, '')
    }
  }, [state])

  useEffect(()=> {
    if ((location.pathname.toLowerCase() === '/subscribe') && !activeTab) {
      // setActive('balance');
      // profile.setShowSubscriptionModal(true)
      window.location.replace(process.env.REACT_APP_SUBSCRIPTION_REDIRECT || '')
    }
  }, [])
  useEffect(()=> {
    if (activeTab === 'balance') {
      // setActive('balance');
      // profile.setShowSubscriptionModal(true)
      window.location.replace(process.env.REACT_APP_SUBSCRIPTION_REDIRECT || '')
    }
  }, [activeTab])

  return (
    <div>
      <Tabs
        items={[
          {
            key: 'general',
            title: t('general-info')
          },
          {
            key: 'security',
            title: t('security')
          },
          // {
          //   key: 'balance',
          //   title: t('subscription')
          // },
          {
            key: 'download',
            title: t('download')
          }
        ]}
        defaultActive={activeTab}
      >
        <div>
          <GeneralTab />
        </div>
        <div>
          <SecurityTab />
        </div>
        {/* <div>
          <BalanceTab />
        </div> */}
        <div>
          <DownloadTab />
        </div>
      </Tabs>
    </div>
  )
}

export default Profile
