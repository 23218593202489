import clsx from 'clsx'
import Loader from 'components/Loader'
import iconLink from 'assets/svg/link.svg'
import iconImage from 'assets/svg/image.svg'
import iconModel from 'assets/svg/3d-rotate.svg'
import iconVideo from 'assets/svg/video-square.svg'
import iconDelete from 'assets/svg/close-circle.svg'
import iconDocument from 'assets/svg/document-text.svg'
import iconStatusDone from 'assets/svg/tick-circle.svg'
import iconStatusFailed from 'assets/svg/close-circle-red.svg'
import React, { useEffect, useState } from 'react'
import { useDocuments } from 'utils/hooks/useContext'
import { RichDocument, RichFile, TFileStatus } from 'utils/files'

const DOCUMENT_STATUS_ICON: { [key in TFileStatus]: React.ReactNode } = {
  done: <img src={iconStatusDone} alt='' />,
  failed: <img src={iconStatusFailed} alt='' />,
  loading: <Loader color='light' />
}

interface DocumentFileProps {
  richDoc: RichDocument
  onLoad(richDoc: RichDocument): void
  onDelete(): void
}

const DocumentFile: React.FC<DocumentFileProps> = (props) => {
  const { list } = useDocuments()
  const { richDoc, onLoad, onDelete } = props

  const [fileText, setFileText] = useState('')

  useEffect(() => {
    ;(async () => {
      if (richDoc.docType === 'text' && richDoc instanceof RichFile) {
        setFileText(await richDoc.readFileAsText())
      }
      await richDoc.uploadFile()
      onLoad(richDoc)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const previewContent = (() => {
    const uploadedDoc = list.find((doc) => doc._id === richDoc.uploadedId)

    switch (richDoc.docType) {
      case 'image':
        return (
          <img
            alt=''
            src={uploadedDoc?.thumb || iconImage}
            className={uploadedDoc?.thumb ? 'b-file__preview' : 'b-file__icon'}
          />
        )

      case 'pdf':
      case 'zip':
        return <img src={iconDocument} alt='' className='b-file__icon' />

      case 'model':
        return <img src={iconModel} alt='' className='b-file__icon' />

      case 'link':
        return (
          <img
            alt=''
            src={uploadedDoc?.thumb || iconLink}
            className={uploadedDoc?.thumb ? 'b-file__preview' : 'b-file__icon'}
          />
        )

      case 'video':
        return (
          <img
            alt=''
            src={uploadedDoc?.thumb || iconVideo}
            className={uploadedDoc?.thumb ? 'b-file__preview' : 'b-file__icon'}
          />
        )

      case 'text':
        return <p>{fileText}</p>

      default:
        return <></>
    }
  })()

  return (
    <div className='b-file'>
      {previewContent}

      <div
        className={clsx(
          'b-file__overlay',
          `b-file__overlay--${richDoc.uploadStatus}`
        )}
      />

      {richDoc.uploadStatus !== 'loading' && (
        <button onClick={onDelete} className='b-file__delete'>
          <img src={iconDelete} alt='x' />
        </button>
      )}

      <div className='b-file__status'>
        {DOCUMENT_STATUS_ICON[richDoc.uploadStatus]}
      </div>
    </div>
  )
}

export default DocumentFile
