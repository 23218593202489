import * as yup from 'yup'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import TextField from 'components/TextField/TextField'
import Typography from 'components/Typography/Typography'
import { t } from 'utils/localization'
import { useFormik } from 'formik'
import { useProfile } from 'utils/hooks/useContext'
import { formatPrice, getEndDate } from 'utils/helpers'
import React, { useEffect, useState } from 'react'
import Select from 'components/Select'
import { PRICE_PER_ROOM } from 'utils/constants'
import { IOption } from 'utils/types'

interface IShareBalanceForm {
  email: string
}

const ShareBalanceModal = () => {
  const profile = useProfile()

  const defaultPeriod:IOption = { label: `${t('this-month')}`, value: '0 '};
  const [numberOfRooms, setNumberOfRooms] = useState(0)
  const [period, setPeriod] = useState(defaultPeriod)
  const [price, setPrice] = useState(0)
  const [priceFromNow, setPriceFromNow] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!profile.slotPriceFromNow) {
      profile.getSlotPriceFromNow();
    }
    setPriceFromNow(profile.slotPriceFromNow/100);
  }, [profile])

  const handleShare = (values: IShareBalanceForm) => {
    profile.giftSubscriptionSlots(numberOfRooms, Number(period.value), values.email)
  }

  const {
    values,
    errors,
    touched,
    resetForm,
    handleChange,
    handleSubmit,
  } = useFormik<IShareBalanceForm>({
    onSubmit: (values) => handleShare(values),
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().required(t('required')).email(t('invalid-email')),
    })
  })

  useEffect(() => {
    if (!profile.showShareBalanceModal) {
      setTimeout(() => {
        resetForm()
        profile.setShowSuccessModal(false)
      }, 500)
    }
  }, [resetForm, profile.showShareBalanceModal])

  const balance = profile.user?.cloudData.balance ? profile.user?.cloudData.balance/100 :  0;

  useEffect(()=> {
    const calc = (numberOfRooms * PRICE_PER_ROOM) * Number(period.value) + numberOfRooms * priceFromNow;
    setPrice(calc);
  }, [numberOfRooms, period])

  const complete = () => {
    profile.setShowShareBalanceModal(false);
    profile.setShowSuccessModal(false);
    resetForm();
    setLoading(false);
  }
  
  const blurEmail = () => {
    profile.checkUserByEmail(values.email); 
  }
  const focusEmail = () => {
    profile.setShowUserNotExist(false);
  }

  return (
    <Modal
      isOpen={profile.showShareBalanceModal}
      onClose={() => profile.setShowShareBalanceModal(false)}
      hideClose
      className='b-payment-modal'
    >
      {profile.showSuccessModal ? (
        <>
          <Typography as='h1' centered>
            {t('donated-rooms')}
          </Typography>

          <div className='b-spacer__2' />

          <Typography size='md' centered>
            {t('congratulations-on-sharing')}
          </Typography>

          <div className='b-spacer__1' />

          <Typography size='md' weight='bold' centered>
            {values.email}{' '}{numberOfRooms} {t('rooms')}
          </Typography>

          <div className='b-spacer__6' />

          <Button
            size='lg'
            onClick={complete}
            fullWidth
          >
            {t('confirm')}
          </Button>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Typography as='h1' centered>
            {t('gift-rooms')}
          </Typography>

          <div className='b-spacer__2' />

          <Typography size='md' centered>
            {t('enter-gift-account')}
          </Typography>

          <div className='b-spacer__6' />

          <TextField
            size='lg'
            name='email'
            label={t('email')}
            value={values.email}
            error={touched.email && errors.email || profile.showUserNotExist && t('user-not-found')}
            placeholder={t('enter-email')}
            onChangeFormik={handleChange}
            onBlur={blurEmail}
            onFocus={focusEmail}
          />

          <div className='b-spacer__2' />

          <Select
            size='lg'
            label={t('rooms')}
            value={`${numberOfRooms}`}
            onChange={(value) => setNumberOfRooms(Number(value))}
            placeholder={t('select-rooms')}
            options={[
              { label: `1 ${t('room')}`, value: '1' },
              { label: `2 ${t('room')}`, value: '2' },
              { label: `3 ${t('room')}`, value: '3' },
              { label: `4 ${t('room')}`, value: '4' }
            ]}
          />
          <div className='b-spacer__2' />
          <Select
            size='lg'
            label={t('period')}
            value={period.value}
            onChange={(value, option:IOption) => setPeriod(option)}
            placeholder={t('select-period')}
            options={[
              defaultPeriod,
              { label: `1 ${t('many-months')}`, value: '1' },
              { label: `2 ${t('many-months')}`, value: '2' },
              { label: `3 ${t('many-months')}`, value: '3' }
            ]}
            renderOption={(option) => (
              <div className='b-row'>
                <Typography>{option.label}&nbsp;</Typography>
                <Typography size='md' color='grey'>({t('ends')} {getEndDate(Number(option.value))})</Typography>
              </div>
            )}
          />
          <div className='b-spacer__2' />

          <TextField
            size='lg'
            name='currentBalance'
            label={t('current-balance')}
            value={formatPrice(balance)}
            disabled
          />
          <div className='b-spacer__2' />

          <TextField
            size='lg'
            name='price'
            label={t('price')}
            value={formatPrice(price)}
            disabled
          />

          <div className='b-spacer__6' />

          <div className='b-payment-modal__footer'>
            <Button
              size='lg'
              variant='shadow'
              onClick={() => profile.setShowShareBalanceModal(false)}
            >
              {t('cancel')}
            </Button>

            <Button type='submit' size='lg' disabled={loading || (price > balance) || profile.showUserNotExist || !values.email}>
              {t('confirm')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  )
}

export default ShareBalanceModal
