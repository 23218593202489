import Button from 'components/Button'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { t } from './localization'
import { useEffect } from 'react'
import {
  confirmable,
  ReactConfirmProps,
  createConfirmation
} from 'react-confirm'

interface IConfirmOptions {
  style?: 'default' | 'warning'
  okText?: string
  cancelText?: string
}

const ConfirmDialog: React.FC<ReactConfirmProps & IConfirmOptions> = (
  props
) => {
  const {
    show,
    proceed,
    confirmation,
    okText = t('continue'),
    cancelText = t('cancel'),
    style = 'default'
  } = props

  useEffect(() => {
    const callback = (event: MouseEvent | TouchEvent) => {
      event.stopPropagation()
    }

    window.addEventListener('mousedown', callback, true)
    window.addEventListener('touchstart', callback, true)

    return () => {
      window.removeEventListener('mousedown', callback, true)
      window.removeEventListener('touchstart', callback, true)
    }
  }, [])

  return (
    <Modal
      isOpen={show}
      onClose={() => proceed('')}
      className='b-confirm'
      hideClose
    >
      <Typography size='lg' weight='semi' centered>
        {confirmation}
      </Typography>

      <div className='b-confirm__controls'>
        <Button variant='outline' onClick={() => proceed('')}>
          {cancelText}
        </Button>
        <Button
          variant={style === 'default' ? 'primary' : 'danger'}
          onClick={() => proceed('true')}
        >
          {okText}
        </Button>
      </div>
    </Modal>
  )
}

export default function confirm(text: string, options?: IConfirmOptions) {
  return createConfirmation(confirmable(ConfirmDialog))({
    confirmation: text,
    ...options
  })
}
