import mixpanel from 'mixpanel-browser'
import { IUser } from './types'

const GOALS: { [key: string]: string } = {
  Signin: 'signin',
  Signup: 'signup',
  ChangePlan: 'change-plan',
  UploadDocuments: 'upload-documents',
  DownloadsOculus: 'go-to-oculus-store',
  DownloadsWindows: 'download-windows-app',
  ChangePlanToPro: 'select-plan-pro',
  ChangePlanToFree: 'select-plan-free',
  ChangePlanToIndie: 'select-plan-indie',
  CancelSubscription: 'cancel-subscription'
}

const AnalyticsService = {
  init() {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
    }
  },
  identify(user: IUser) {
    mixpanel.identify(user.authData.email)

    let userName = user.cloudData.tag.split('#')[0]

    mixpanel.people.set({
      Name: userName,
      Email: user.authData.email,
      $name: userName,
      $email: user.authData.email,
      'Is Guest': false,
    })
  },
  track(event: keyof typeof GOALS | string) {
    mixpanel.track((GOALS[event] || event) as string)
  }
}

export default AnalyticsService
