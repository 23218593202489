import { RefObject, useEffect } from 'react'

export type TUseOnClickOutsideOptions = {
  ignoreClassNames?: string[]
}

type TUseOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void,
  options?: TUseOnClickOutsideOptions
) => void

const useOnClickOutside: TUseOnClickOutside = (ref, handler, options) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (options?.ignoreClassNames) {
        if (
          options.ignoreClassNames.some((className) =>
            // @ts-ignore
            event.target.classList.contains(className)
          )
        ) {
          return
        }
      }

      // @ts-ignore
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      if (handler) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, options])
}

export default useOnClickOutside
