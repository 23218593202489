import * as yup from 'yup'
import Button from 'components/Button'
import useRequest from 'utils/hooks/useRequest'
import PasswordField from 'components/PasswordField'
import LoadingWrapper from 'components/LoadingWrapper'
import { t } from 'utils/localization'
import { alerts } from 'components/Toast/Toast'
import { useFormik } from 'formik'

interface ISecurityForm {
  password: ''
  newPassword: ''
  newPasswordConfirm: ''
}

const SecurityTab = () => {
  const changePasswordQuery = useRequest('/password', {
    method: 'PUT',
    onSuccess() {
      resetForm()
      alerts.success(t('password-updated'))
    }
  })

  const handleChangePassword = (values: ISecurityForm) => {
    changePasswordQuery.request(values)
  }

  const { values, errors, touched, resetForm, handleChange, handleSubmit } =
    useFormik<ISecurityForm>({
      onSubmit: (values) => handleChangePassword(values),
      initialValues: {
        password: '',
        newPassword: '',
        newPasswordConfirm: ''
      },
      validationSchema: yup.object({
        password: yup.string().required(t('required')),
        newPassword: yup
          .string()
          .required(t('required'))
          .min(8, t('password-invalid')),
        newPasswordConfirm: yup
          .string()
          .test(
            'check-password-confirm',
            t('verify-password-invalid'),
            (value, { parent }) => !!value && value === parent.newPassword
          )
      })
    })

  return (
    <form onSubmit={handleSubmit} className='b-profile-form'>
      <LoadingWrapper isLoading={changePasswordQuery.isLoading}>
        <PasswordField
          size='lg'
          name='password'
          value={values.password}
          error={touched.password && errors.password}
          label={t('current-password')}
          placeholder={t('password')}
          hideRecovery
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__4' />

        <PasswordField
          size='lg'
          name='newPassword'
          value={values.newPassword}
          error={touched.newPassword && errors.newPassword}
          label={t('new-password')}
          placeholder={t('password')}
          hideRecovery
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__2' />

        <PasswordField
          size='lg'
          name='newPasswordConfirm'
          value={values.newPasswordConfirm}
          error={touched.newPasswordConfirm && errors.newPasswordConfirm}
          placeholder={t('password')}
          hideRecovery
          onChangeFormik={handleChange}
        />

        <div className='b-spacer__5' />

        <Button size='lg' type='submit' fullWidth>
          {t('save')}
        </Button>
      </LoadingWrapper>
    </form>
  )
}

export default SecurityTab
