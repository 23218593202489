import clsx from 'clsx'
import { ChangeEvent } from 'react'
import './styles.scss'

interface CheckboxProps {
  id: string
  label?: string | React.ReactNode
  checked?: boolean
  disabled?: boolean
  onChange?(checked: boolean): void
  className?: boolean
  onChangeFormik?(event: ChangeEvent<HTMLInputElement>): void
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { id, label, onChange, className, onChangeFormik, ...rest } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked)
    }
    if (onChangeFormik) {
      onChangeFormik(event)
    }
  }

  return (
    <div className={clsx('b-checkbox', className)}>
      <input
        id={id}
        name={id}
        type='checkbox'
        onChange={handleChange}
        className='b-checkbox__input'
        {...rest}
      />
      <label htmlFor={id} className='b-checkbox__label'>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
