import clsx from 'clsx'
import './styles.scss'

interface TypographyProps {
  as?: 'h1' | 'h2'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  color?: 'black' | 'light' | 'grey' | 'primary' | 'secondary' | 'error'
  weight?: 'normal' | 'medium' | 'semi' | 'bold'
  inline?: boolean
  centered?: boolean
  className?: string
}

const Typography: React.FC<TypographyProps> = (props) => {
  const {
    as,
    size = 'md',
    color = 'black',
    weight = 'normal',
    inline,
    centered,
    children,
    className
  } = props

  const classNames = clsx(
    'b-text',
    `b-text--${size}`,
    `b-text--${color}`,
    `b-text--${weight}`,
    inline && 'b-text--inline',
    centered && 'b-text--centered',
    className
  )

  switch (as) {
    case 'h1':
      return <h1 className={classNames}>{children}</h1>

    case 'h2':
      return <h2 className={classNames}>{children}</h2>

    default:
      return <div className={classNames}>{children}</div>
  }
}

export default Typography
