import ApiService from 'utils/api'
import { alerts } from 'components/Toast/Toast'
import { useCallback, useEffect, useState } from 'react'
import { IQueryParams, TApiError, TRequestMethod } from 'utils/types'

interface IRequestOptions<TData> {
  method?: TRequestMethod
  enabled?: boolean
  onError?(error: TApiError, payload?: { [key: string]: string }): void
  onSuccess?(data: TData, payload?: { [key: string]: string }): void
  queryOptions?: object
  successMessage?: string
  hideErrorMessage?: boolean
}

export function useRequest<TData = any>(
  url: string,
  options: IRequestOptions<TData> = {}
) {
  const {
    method = 'GET',
    enabled = true,
    onError,
    onSuccess,
    queryOptions = {},
    successMessage,
    hideErrorMessage
  } = options

  const [data, setData] = useState<TData | null>(null)
  const [error, setError] = useState<TApiError | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  const request = useCallback(
    (payload?: object, params?: IQueryParams) => {
      setError(null)
      setLoading(true)

      ApiService<TData>({
        url: url,
        data: payload,
        params: params,
        method: method,
        ...queryOptions
      })
        .then((result) => {
          if (result.error) {
            throw result.error
          } else if (result.data) {
            setData(result.data)
            onSuccess && onSuccess(result.data, payload as any)

            if (successMessage) {
              alerts.success(successMessage)
            }
          }
        })
        .catch((error) => {
          setError(error)
          onError && onError(error, payload as any)

          if (!hideErrorMessage) {
            alerts.error(error.message)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  )

  useEffect(() => {
    if (method === 'GET' && enabled) {
      request()
    }
  }, [method, request, enabled])

  return { data, isLoading, error, request }
}

export default useRequest
