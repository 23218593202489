import client, { server } from 'utils/socket'
import ApiService from 'utils/api'
import { alerts } from 'components/Toast/Toast'
import { cropImageByUrl } from './useCropper'
import { getDocumentType, getUserIdFromToken } from 'utils/helpers'

import {
  IUser,
  IContext,
  IDocumentFile,
  IUserAuthData,
  IUserCloudData,
  IDocumentFilter,
  IDocument
} from 'utils/types'
import React, {
  FC,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  useMemo
} from 'react'
import AnalyticsService from 'utils/analytics'
import { t } from 'utils/localization'

const AppContext = createContext<IContext>({
  profile: {
    user: null,
    login: () => {},
    logout: () => {},
    loading: true,
    setUser: () => {},
    firstTimeReg: false,
    showDownload: false,
    showPlanModal: false,
    showShareBalanceModal: false,
    showEmailConfirmation: false,
    showSubscriptionModal: false,
    showSuccessModal: false,
    showRefillBalanceModal: false,
    showUnsubscribeRoomsModal: false,
    setFirstTimeReg: () => {},
    setShowDownload: () => {},
    setShowPlanModal: () => {},
    setShowShareBalanceModal: () => {},
    setShowEmailConfirmation: () => {},
    setShowSubscriptionModal: () => {},
    setShowRefillBalanceModal: () => {},
    setShowUnsubscribeRoomsModal: () => {},
    setSubscriptionSlots: () => {},
    setShowSuccessModal: () => {},
    giftSubscriptionSlots: () => {},
    getSlotPriceFromNow: () => {},
    slotPriceFromNow: 0,
    showUserNotExist: false,
    setShowUserNotExist: () => {},
    checkUserByEmail: () => {}
  },
  documents: {
    list: [],
    reset: () => {},
    setList: () => {},
    loadList: () => {},
    filters: {
      text: '',
      types: []
    },
    isLoading: false,
    setFilters: () => {},
    filteredList: []
  }
})

export const ProvideAppContext: FC = ({ children }) => {
  const profile = useProvideProfile()
  const documents = useProvideDocuments()

  return (
    <AppContext.Provider value={{ profile, documents }}>
      {children}
    </AppContext.Provider>
  )
}

export const useProfile = () => {
  const { profile } = useContext(AppContext)
  return profile
}

export const useDocuments = () => {
  const { documents } = useContext(AppContext)
  return documents
}

const useProvideProfile = () => {
  const [user, setUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [showDownload, setShowDownload] = useState<boolean>(false)
  const [firstTimeReg, setFirstTimeReg] = useState<boolean>(false)
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false)
  const [showEmailConfirmation, setShowEmailConfirmation] =
    useState<boolean>(false)
  const [showRefillBalanceModal, setShowRefillBalanceModal] =
    useState<boolean>(false)
  const [showSubscriptionModal, setShowSubscriptionModal] =
    useState<boolean>(false)
  const [showShareBalanceModal, setShowShareBalanceModal] =
    useState<boolean>(false)
  const [showUnsubscribeRoomsModal, setShowUnsubscribeRoomsModal] =
    useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] =
    useState<boolean>(false)
  const [slotPriceFromNow, setSlotPriceFromNow] =
    useState<number>(0)
  const [showUserNotExist, setShowUserNotExist] =
    useState<boolean>(false)
    
  const subscribeToPlanChange = useCallback(() => {
    server.on('planChanged', async (planId: string) => {
      try {
        const token = localStorage.getItem('token')
        const cloudData: IUserCloudData = await client.login(token)

        // if (cloudData.plan.id.includes('Annual')) {
        //   //@ts-ignore
        //   cloudData.plan.id = cloudData.plan.id.replace('Annual', '')
        //   cloudData.plan.isAnnual = true
        // }

        setUser((user) => {
          if (!user) {
            return null
          }
          return {
            ...user,
            cloudData: cloudData,
            // isPlanLimited:
            //   cloudData?.plan?.id === 'free' || cloudData?.plan?.id === 'indie'
          }
        })
      } catch {
        /* empty */
      }
    })
  }, [])

  const loadPlans = useCallback(async () => {
    try {
      // const plans: IPlan[] = await client.listPlans()
      // setPlans(plans)
    } catch {
      /*empty*/
    }
  }, [])

  const login = useCallback(
    async (token: string, callback?: (id?: string) => void) => {
      try {
        localStorage.setItem('token', token)
        const res = await ApiService.get<{ user: IUserAuthData }>('/auth-token')

        if (res.data) {
          const cloudData: IUserCloudData = await client.login(token)
          const avatar: { doc: IDocument; rect: string } =
            await client.getMyProfilePic()

          subscribeToPlanChange()
          await loadPlans()

          const userStateData: IUser = {
            avatar: avatar
              ? {
                  ...avatar.doc,
                  thumb: await cropImageByUrl(avatar.doc, avatar.rect)
                }
              : null,
            authData: res.data.user,
            cloudData: {
              ...cloudData,
              //@ts-ignore
              // plan: cloudData.plan.id.includes('Annual')
              //   ? {
              //       ...cloudData.plan,
              //       id: cloudData.plan.id.replace('Annual', ''),
              //       isAnnual: true
              //     }
              //   : cloudData.plan
            },
            //   cloudData?.plan?.id === 'free' || cloudData?.plan?.id === 'indie'
          }

          setUser(userStateData)
          AnalyticsService.identify(userStateData)

          if (callback) {
            callback(res.data.user.id)
          }
        } else {
          throw res.error
        }
      } catch (error) {
        localStorage.setItem('token', '')
        if (error instanceof Error) {
          alerts.error(error.message)
        }
      }
    },
    [loadPlans, subscribeToPlanChange]
  )

  const logout = useCallback(async () => {
    localStorage.setItem('token', '')
    setUser(null)
  }, [])

  const load = useCallback(async () => {
    try {
      setLoading(true)

      const token = localStorage.getItem('token')

      if (token) {
        await login(token)
      }
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [login])

  useEffect(() => {
    load()
    //@ts-ignore
    window.login = (token: string) => {
      localStorage.setItem('token', token)
      load()
    }
  }, [load])

  const setSubscriptionSlots = useCallback(async (slots: number, method: string) => {
    try {
      const result = await client.setSubscriptionSlots(slots);
      if (result) {
        const token = localStorage.getItem('token')
        const cloudData: IUserCloudData = await client.login(token);
        setUser((user) => {
          if (!user) {
            return null
          }
          return {
            ...user,
            cloudData: cloudData
          }
        })
        if (method === 'remove') {
          setShowUnsubscribeRoomsModal(false);
        } else {
          setShowSuccessModal(true);
        }
      } else {
        if (user?.cloudData.slotsRenew && (slots < user.cloudData.slotsRenew)) {
          alerts.error(t('remove-room-failure'))
        } else if (user?.cloudData.slotsRenew && (slots > user.cloudData.slotsRenew)) {
          alerts.error(t('add-room-failure'))
        } else {
          alerts.error(t('update-room-failure'))
        }
        
      }
    } catch {
      alerts.error(t('update-room-failure'))
    }
    
  }, [user])

  const getSlotPriceFromNow = useCallback(async () => {
    const price = await client.getSlotPriceFromNow();
    setSlotPriceFromNow(price)
  }, [])
  
  const giftSubscriptionSlots = useCallback(async (roomCount: number, monthCount: number, email: string) => {
    const result = await client.giftSlotsByEmail(roomCount, monthCount, email);
    if (result) {
      setShowSuccessModal(true);
      const token = localStorage.getItem('token')
      const cloudData: IUserCloudData = await client.login(token);
      setUser((user) => {
        if (!user) {
          return null
        }
        return {
          ...user,
          cloudData: cloudData
        }
      })
    } else {
      alerts.error(t('gift-room-failure'))
    }
  }, [])

  const checkUserByEmail = useCallback(async (email: string) => {
    const result = await client.findUserByEmail(email);
    setShowUserNotExist(!result)
  }, [])

  return {
    user,
    login,
    logout,
    loading,
    setUser,
    firstTimeReg,
    showDownload,
    showPlanModal,
    setFirstTimeReg,
    setShowDownload,
    setShowPlanModal,
    showShareBalanceModal,
    showSubscriptionModal,
    showSuccessModal,
    showEmailConfirmation,
    showRefillBalanceModal,
    showUnsubscribeRoomsModal,
    setShowShareBalanceModal,
    setShowSubscriptionModal,
    setShowEmailConfirmation,
    setShowRefillBalanceModal,
    setShowUnsubscribeRoomsModal,
    setSubscriptionSlots,
    setShowSuccessModal,
    giftSubscriptionSlots,
    getSlotPriceFromNow,
    slotPriceFromNow,
    showUserNotExist,
    setShowUserNotExist,
    checkUserByEmail
  }
}

const useProvideDocuments = () => {
  const [list, setList] = useState<IDocumentFile[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true)
  const [filters, setFilters] = useState<IDocumentFilter>({
    text: '',
    types: []
  })

  const userId = getUserIdFromToken()

  useEffect(() => {
    server.on('docsUpdated', (...data: any[]) => {
      try {
        const addedFiles: IDocument[] = data?.[0] || []
        const deletedFiles: string[] = data?.[1] || []

        setList((list) => {
          let documentsList = [...list]
          if (addedFiles.length) {
            addedFiles.forEach((doc: any) => {
              const types = getDocumentType(doc.mime, doc.url)
              if (types && userId === doc.scope) {
                documentsList.push({
                  ...doc,
                  type: types.type,
                  docType: types.docType
                })
              }
            })
          }
          if (deletedFiles.length) {
            documentsList = documentsList.filter(
              (doc) => !deletedFiles.includes(doc._id)
            )
          }
          return documentsList
        })
      } catch {
        /* empty */
      }
    })
  }, [userId])

  const reset = useCallback(() => {
    setList([])
    setLoading(false)
    setFirstLoad(true)
    setFilters({
      text: '',
      types: []
    })
  }, [])

  const loadList = useCallback(
    async (forceLoad?: boolean) => {
      if (isFirstLoad || forceLoad) {
        try {
          setLoading(true)
          const documents: any[] = await client.listDocs()
          const documentsList: IDocumentFile[] = []

          documents.forEach((doc) => {
            const types = getDocumentType(doc.mime, doc.url)
            if (types && userId === doc.scope) {
              documentsList.push({
                ...doc,
                type: types.type,
                docType: types.docType
              })
            }
          })

          setList(documentsList)
        } catch {
          /* empty */
        } finally {
          setLoading(false)
          setFirstLoad(false)
        }
      }
    },
    [userId, isFirstLoad]
  )

  const filteredList = useMemo(() => {
    let filtred = [...list]

    if (filters.types.length) {
      filtred = filtred.filter((item) => filters.types.includes(item.type))
    }
    if (filters.text) {
      filtred = filtred.filter((item) =>
        item.title
          ? item.title
              .toLocaleLowerCase()
              .search(filters.text.toLocaleLowerCase()) > -1
          : false
      )
    }

    return filtred
  }, [list, filters])

  return {
    list,
    reset,
    setList,
    filters,
    loadList,
    isLoading,
    setFilters,
    filteredList
  }
}