import clsx from 'clsx'
import Modal from 'components/Modal'
import Button from 'components/Button'
import iconUser from 'assets/svg/user.svg'
import Typography from 'components/Typography'
import BalanceModal from 'components/BalanceModal'
import ShareBalanceModal from 'components/ShareBalanceModal'
import SubscriptionModal from 'components/SubscriptionModal'
// import AnalyticsService from 'utils/analytics'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { TDictionary } from 'utils/types'
import { formatPrice } from 'utils/helpers'
import UnsubscribeRoomsModal from 'components/UnsubscribeRoomsModal'
import {pushDataLayer} from "../../../utils/gtm";

const PAGE_TITLE: TDictionary = {
  '/profile': 'profile',
  '/documents': 'files'
}

interface HeaderProps {
  onBurgerClick(): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const { onBurgerClick } = props
  const { pathname } = useLocation()
  const profile = useProfile()
  const navigate = useNavigate()

  if (!profile.user) {
    return null
  }

  const handleClickSubscription = () => {
    // navigate('/profile', {state: {tab: 'balance', modal: 'subscription' }})   
    window.location.replace(process.env.REACT_APP_SUBSCRIPTION_REDIRECT || '')
    // profile.setShowSubscriptionModal(true)
    // pushDataLayer({
    //   event: "addtocart",
    //   category: "Users",
    //   user_id: profile.user?.authData.id,
    //   origin: window.location.origin,
    // });
  }
  const balance = profile.user.cloudData.balance/100 || 0

  return (
    <header className='b-header'>
      <div className='b-row b-row__align-center'>
        <button onClick={onBurgerClick} className='b-header__burger'>
          <span />
          <span />
          <span />
        </button>

        <h1>{t(PAGE_TITLE[pathname])}</h1>
      </div>

      <div className='b-row b-row__align-center'>
        {/* <LanguageSelect />

        <hr className='b-header__divider' /> */}

        <Button
          onClick={handleClickSubscription}
          variant='shadow'
          className='b-header__subscription'
        >
          {t('subscribe')}
        </Button>
        <Button
          variant='primary'
          className='b-header__weblink'
        >
          <a href="https://app.beyondreal.life/" target="_blank">
            {t('go3d')}
          </a>
        </Button>

        <hr className='b-header__divider' />

        <div className='b-header__profile'>
          <img
            src={profile.user.avatar?.thumb || iconUser}
            alt=''
            className={clsx(
              'b-header__avatar',
              !profile.user.avatar?.thumb && 'b-header__avatar--placeholder'
            )}
          />

          <div className='b-header__profile-info'>
            <Typography size='sm' weight='semi'>
              {profile.user.cloudData.tag}
            </Typography>
          </div>
        </div>
      </div>

      <BalanceModal />
      <ShareBalanceModal />
      <SubscriptionModal />
      <UnsubscribeRoomsModal />
    </header>
  )
}

export default Header
