import clsx from 'clsx'
import Loader from 'components/Loader'
import './styles.scss'

interface LoadingWrapperProps {
  isLoading?: boolean
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = (props) => {
  const { isLoading, children } = props

  return (
    <div className='b-loading'>
      {isLoading && (
        <div className='b-loading__indicator'>
          <Loader />
        </div>
      )}
      <div className={clsx(isLoading && 'b-loading__container')}>
        {children}
      </div>
    </div>
  )
}

export default LoadingWrapper
