import Button from 'components/Button'
import Tabs from 'components/Tabs'
import VrPlatformTab from 'pages/Help/components/VrPlatformTab'
import WebPlatformTab from 'pages/Help/components/WebPlatformTab'
import { useNavigate } from 'react-router-dom'
import { t } from 'utils/localization'
import './styles.scss'
import iconLogo from 'assets/images/Logo_black_2.png'
import Typography from 'components/Typography'
import { Link } from 'react-router-dom'

const Header = () => {
    const navigate = useNavigate()
    return (
        <header className='b-header'>
            <div className='b-row b-row__align-center'>
                <div className='b-side-menu__logo no-auth-logo'>
                    <img src={iconLogo} alt='BEYONDREAL' />
                </div>
                <Typography weight='semi' size="lg" className='headin-no-outh-layout'>{t('help-center')}</Typography>
            </div>

            <div className='b-row b-row__align-center'>
                <a href='https://beyondreal.life/about/' className='b-header-noauth_link'>
                    {t('about')}
                </a>
                <a href='https://beyondreal.life/pricing/' className='b-header-noauth_link'>
                    {t('plans')}
                </a>
                <a href='https://beyondreal.life/contact/' className='b-header-noauth_link'>
                    {t('contact')}
                </a>
                <hr className='b-header__divider' />
                <Button
                    onClick={() => navigate('/signin')}
                    variant='primary'
                    className='b-header__subscription'
                    >
                    {t('signin')}
                </Button>
            </div>
            </header>
    )
}

const HelpNoAuth = () => {
  return (
    <div className='b-layout'>
      <div className='b-layout__container'>
        <Header/>

        <main className='b-layout__content no-auth-content'>
            <div>
                <Tabs
                    items={[
                    {
                        key: 'webplatform',
                        title: t('web-platform')
                    },
                    {
                        key: 'vrplatform',
                        title: t('vr-platform')
                    }
                    ]}
                >
                    <div>
                        <WebPlatformTab />
                    </div>
                    <div>
                        <VrPlatformTab />
                    </div>
                </Tabs>
            </div>
        </main>
      </div>
    </div>
  )
}

export default HelpNoAuth;
