import { ToastContainer, Slide, toast } from 'react-toastify'
import './styles.scss'

const Toast = () => {
  return (
    <ToastContainer
      position='top-right'
      className='b-toast'
      transition={Slide}
      newestOnTop
      hideProgressBar
    />
  )
}

export default Toast

export const alerts = {
  info(message: string) {
    return toast(message)
  },
  error(message: string) {
    return toast(message, {
      type: toast.TYPE.ERROR
    })
  },
  success(message: string) {
    return toast(message, {
      type: toast.TYPE.SUCCESS
    })
  }
}
