import clsx from 'clsx'
import Loader from 'components/Loader'
import Layout from 'components/Layout'
import AuthLayout from 'components/AuthLayout'
import SinginPage from './SinginPage'
import SingupPage from './SingupPage'
import ProfilePage from './Profile'
import SuccessAuth from './SuccessAuth'
import ConfirmEmail from './ConfirmEmail'
import PasswordReset from './PasswordReset'
import DocumentsPage from './Documents'
import LocaleService from 'utils/localization'
import PasswordRecovery from './PasswordRecovery'
import bgSuccess from 'assets/images/Congratulate.jpg'
import { Locale } from 'utils/enums'
import { useEffect } from 'react'
import { useProfile } from 'utils/hooks/useContext'
import { DownloadModal } from './Profile/components/DownloadTab'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Help from './Help'
import HelpNoAuth from './HelpNoAuth'
import SubscribeNoAuth from './SubscribeNoAuth'

export const AuthPages = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path='/plan' element={<Navigate to='/signin' />} />
        <Route path='/signin' element={<SinginPage />} />
        <Route path='/subscribe' element={<SubscribeNoAuth />} />
        <Route path='/signup' element={<SingupPage />} />
        <Route path='/success' element={<SuccessAuth />} />
        <Route path='/recovery' element={<PasswordRecovery />} />
        <Route path='/recovery/:status' element={<PasswordRecovery />} />
        <Route path='/change-password/:token' element={<PasswordReset />} />
        <Route path='*' element={<Navigate replace to='/signin' />} />
      </Routes>
    </AuthLayout>
  )
}

export const HelpPages = () => {
  return (
    <Routes>
      <Route path='/help' element={<HelpNoAuth />} />
    </Routes>
  )
}

export const TariffPages = () => {
  const { firstTimeReg } = useProfile()

  return (
    <Routes>
      <Route
        path='/success'
        element={
          firstTimeReg ? (
            <AuthLayout>
              <ConfirmEmail />
            </AuthLayout>
          ) : (
            <Navigate replace to='/plan' />
          )
        }
      />
      <Route path='*' element={<Navigate replace to='/success' />} />
    </Routes>
  )
}

export const PersonalAreaPages = () => {
  return (
    <Layout>
      <Routes>
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/subscribe' element={<ProfilePage />} />
        <Route path='/documents' element={<DocumentsPage />} />
        <Route path='/help' element={<Help />} />
        {/* <Route path='/change-plan' element={<TariffSelect />} /> */}
        <Route path='*' element={<Navigate replace to='/profile' />} />
      </Routes>

      <DownloadModal />
    </Layout>
  )
}

interface PagesProps {
  isLocaleLoaded: boolean
}

const Pages: React.FC<PagesProps> = (props) => {
  const { pathname } = useLocation()
  const { user, loading, showEmailConfirmation, login } = useProfile()

  useEffect(() => {
    if (pathname === '/ru') {
      LocaleService.change(Locale.ru)
    } else if (pathname === '/en') {
      LocaleService.change(Locale.en)
    }
  }, [pathname])

  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     const param = new URLSearchParams(location.search).get(
  //       'showPlatformDialog'
  //     )
  //     if (param === 'true') {
  //       setShowDownload(true)
  //     }
  //   }
  // }, [location, setShowDownload])

  // Check if '#auth=' is present and try to login
  useEffect(() => {
    const hashValue = window.location.hash.replace(/^#/, '')
    if (hashValue.startsWith('auth')) {
      window.location.hash = ""
      const token = hashValue.split("=")[1]
      if (token) {
        login(token, () => {
          if (pathname.includes('documents')) {
            window.location.hash = "upload"
          }
        })
      }
    }
  }, [])

  let content = null

  if (!loading) {
    if (!user) {
      if (window.location.pathname === '/help') {
        content = <HelpPages/>
      } else {
        content = <AuthPages />
      }
    }
    // else if (
    //   !user.authData.planIsSelected &&
    //   user.cloudData.plan.id === 'free'
    // ) {
    //   content = <TariffPages />
    // }
    else if (showEmailConfirmation) {
      content = (
        <AuthLayout backGroundImage={bgSuccess}>
          <ConfirmEmail />
        </AuthLayout>
      )
    } else {
      content = <PersonalAreaPages />
    }
  }

  return (
    <>
      <div
        className={clsx(
          'b-general-loading',
          !loading && props.isLocaleLoaded && 'b-general-loading--hidden'
        )}
      >
        <Loader />
      </div>
      {content}
    </>
  )
}

export default Pages
