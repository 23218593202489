import clsx from 'clsx'
import Loader from 'components/Loader'
import { Link } from 'react-router-dom'
import { isUrlExternal } from 'utils/helpers'
import './styles.scss'

interface ButtonProps {
  type?: 'button' | 'submit'
  href?: string
  size?: 'md' | 'lg'
  variant?: 'primary' | 'outline' | 'shadow' | 'danger'
  onClick?(): void
  postfix?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  linkProps?: { target?: string }
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    href,
    size = 'md',
    type = 'button',
    variant = 'primary',
    onClick,
    loading,
    postfix,
    disabled,
    children,
    fullWidth,
    className,
    linkProps,
    ...rest
  } = props

  const classNames = clsx(
    'b-button',
    size && `b-button--${size}`,
    variant && `b-button--${variant}`,
    loading && 'b-button--loading',
    fullWidth && 'b-button--fullWidth',
    className
  )

  return href ? (
    <>
      {isUrlExternal(href) ? (
        <a href={href} onClick={onClick} className={classNames} {...linkProps}>
          {children}
        </a>
      ) : (
        <Link to={href} onClick={onClick} className={classNames} {...linkProps}>
          {children}
        </Link>
      )}
    </>
  ) : (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames}
      {...rest}
    >
      {children}

      {!!postfix && <span className='b-button__postfix'>{postfix}</span>}

      {!!loading && (
        <div className='b-button__loader'>
          <Loader
            size={12}
            color={variant === 'primary' ? 'light' : 'primary'}
          />
        </div>
      )}
    </button>
  )
}

export default Button
