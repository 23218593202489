import Modal from 'components/Modal'
import Button from 'components/Button'
import Typography from 'components/Typography'
import imageWindows from 'assets/images/Windows.png'
import imageMetaQuest from 'assets/images/MetaQuest.png'
import AnalyticsService from 'utils/analytics'
import { t } from 'utils/localization'
import { useProfile } from 'utils/hooks/useContext'
import DownloadCard, { PlatformTypes } from 'components/Downloads/DownloadCard'
import {pushDataLayer} from "../../../utils/gtm";

const QUEST_LINK = 'https://www.oculus.com/experiences/quest/6211781368850179'
const WINDOWS_LINK = 'https://auth.beyondreal.life/api/beyondreal_setup.exe'
const VIVE_LINK = 'https://business.vive.com/appstore/detail/c3c165d0-7c10-4c9e-ac2f-3d63c5689142'
const PICO_LINK = 'https://www.picoxr.com/global/software/pico-store'


const DownloadTab = () => {
  const createDownloadTracker = (platform: string) => () => {
      AnalyticsService.track(platform);

      pushDataLayer({
          event: 'brDownloadPlatform',
          platform,
      });
  };

  return (
    <div>
      <Typography weight='semi'>{t('download-platform')}</Typography>

      <div className='b-spacer__3' />

      <div className='b-row b-row-mobile-centered'>
        <DownloadCard 
          type={PlatformTypes.Pico} 
          onClick={createDownloadTracker('DownloadsPico')}
          href={PICO_LINK}
        />
        <DownloadCard 
          type={PlatformTypes.HTC} 
          onClick={createDownloadTracker('DownloadsHTC')}
          href={VIVE_LINK}
        />
      </div>
      <div className='b-row b-row-mobile-centered'>
        <DownloadCard 
          type={PlatformTypes.Meta}
          href={QUEST_LINK}
          onClick={createDownloadTracker('DownloadsOculus')}
        />
        <DownloadCard 
          type={PlatformTypes.Windows}  
          href={WINDOWS_LINK}
          onClick={createDownloadTracker('DownloadsWindows')}
        />
      </div>
    </div>
  )
}

export default DownloadTab

export const DownloadModal = () => {
  const { showDownload, setShowDownload } = useProfile()

  return (
    <Modal isOpen={showDownload} onClose={() => setShowDownload(false)} scrollable={true} className="downloads-modal">
      <div>
        <Typography size='lg' weight='bold' centered className='downloads-moda-head'>
          {t('choose-download-platform')}
        </Typography>
        <div className='b-row download-cards-container'>
          <div className='b-row b-row__align-start b-row__justify-center'>
            <DownloadCard 
              type={PlatformTypes.Pico} 
              onClick={() => AnalyticsService.track('DownloadsPico')}
              href={PICO_LINK}
            />
            <DownloadCard 
              type={PlatformTypes.HTC} 
              onClick={() => AnalyticsService.track('DownloadsHTC')}
              href={VIVE_LINK}
            />
          </div>
          <div className='b-row b-row__align-start b-row__justify-center'>
            <DownloadCard 
              type={PlatformTypes.Meta}
              href={QUEST_LINK}
              onClick={() => AnalyticsService.track('DownloadsOculus')}
            />
            <DownloadCard 
              type={PlatformTypes.Windows}  
              href={WINDOWS_LINK}
              onClick={() => AnalyticsService.track('DownloadsWindows')}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
