import { useEffect, useMemo, useState } from 'react'
import { Tab, Tabs as RTabs, TabList, TabPanel } from 'react-tabs'

import './styles.scss'
import 'react-tabs/style/react-tabs.css'

export interface ITab {
  key: string
  title: string
}

interface TabsProps {
  items: ITab[]
  lazyLoad?: boolean
  defaultActive?: string
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { items, children, lazyLoad, defaultActive } = props

  const [active, setActive] = useState<number>(0)

  useEffect(() => {
    if (defaultActive) {
      const index = items.findIndex((item) => item.key === defaultActive)
      if (index > -1) {
        setActive(index)
      }
    }
  }, [items, defaultActive])

  const content = useMemo(() => {
    const arrayOfContent = Array.isArray(children) ? children : [children]
    return arrayOfContent.filter((item) => !!item)
  }, [children])

  if (!items.length) {
    return null
  }

  return (
    <RTabs
      onSelect={setActive}
      className='b-tabs'
      selectedIndex={active}
      forceRenderTabPanel={!lazyLoad}
      selectedTabClassName='b-tabs__tab--selected'
    >
      <div className='b-tabs__list-wrapper'>
        <TabList className='b-tabs__list'>
          {items.map((item) => (
            <Tab key={item.key} className='b-tabs__tab'>
              {item.title}
            </Tab>
          ))}
        </TabList>
      </div>

      <div className='b-tabs__content'>
        {content.map((item, index) => (
          <TabPanel key={index}>{item}</TabPanel>
        ))}
      </div>
    </RTabs>
  )
}

export default Tabs
