import { Locale } from './enums'
import { TDictionary } from './types'

export const FALLBACK_LOCALE: Locale = Locale.en

class LocalizationService {
  locale: Locale = FALLBACK_LOCALE
  translations: TDictionary = {}

  async init() {
    await this.detectLanguage()
    await this.loadTranslations()
  }

  async detectLanguage() {
    // let locale = localStorage.getItem('locale')

    // if (!locale) {
    //   locale = navigator.language || navigator.languages[0]
    //   locale = locale?.substring(0, 2) || FALLBACK_LOCALE
    // }

    // if (!(locale in Locale)) {
    //   locale = FALLBACK_LOCALE
    // }

    // localStorage.setItem('locale', locale)

    // this.locale = locale as Locale
    this.locale = FALLBACK_LOCALE
  }

  async loadTranslations() {
    try {
      const file = require(`assets/lang/${this.locale}.json`)

      if (!file) {
        throw new Error('Translarion file is not found')
      }

      this.translations = file
    } catch (error) {
      this.translations = {}
    }
  }

  t(key: string, placeholders?: TDictionary): string {
    let value = this.translations[key] || ''

    if (placeholders) {
      Object.entries(placeholders).forEach(([key, placeholder]) => {
        value = value.replace(`{${key}}`, placeholder)
      })
    }

    return value || key
  }

  change(locale: Locale, callback?: () => void) {
    localStorage.setItem('locale', locale)
    callback && callback()
  }
}

const LocaleService = new LocalizationService()

export const t = LocaleService.t.bind(LocaleService)

export default LocaleService
