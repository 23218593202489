import iconLink from 'assets/svg/link.svg'
import iconImage from 'assets/svg/image.svg'
import iconVideo from 'assets/svg/video-square.svg'
import iconModel from 'assets/svg/3d-rotate.svg'
import iconDelete from 'assets/svg/trash.svg'
import iconDocument from 'assets/svg/document-text.svg'
import { IDocumentFile } from 'utils/types'
import { MouseEventHandler, useMemo } from 'react'

interface DocumentPreviewProps {
  document: IDocumentFile
  onSelect(): void
  onDelete(id: string): void
}

const DocumentPreview: React.FC<DocumentPreviewProps> = (props) => {
  const { document, onDelete, onSelect } = props

  const { icon, preview } = useMemo(() => {
    switch (document.type) {
      case 'image':
        return {
          icon: iconImage,
          preview: <img src={document.thumb} alt='' />
        }

      case 'link':
        return {
          icon: iconLink,
          preview: (
            <img
              alt=''
              src={document.thumb || iconLink}
              className={document.thumb ? '' : 'b-document__preview--model'}
            />
          )
        }

      case 'video':
        return {
          icon: iconVideo,
          preview: (
            <img
              src={document.thumb || iconVideo}
              alt=''
              className={
                document.thumb ? undefined : 'b-document__preview--model'
              }
            />
          )
        }

      case 'document':
        return {
          icon: iconDocument,
          preview:
            document.docType === 'text' ? (
              <p>{document.text}</p>
            ) : (
              <img
                className='b-document__preview--model'
                src={iconDocument}
                alt=''
              />
            )
        }

      case 'model':
        return {
          icon: iconModel,
          preview: (
            <img
              className='b-document__preview--model'
              src={iconModel}
              alt=''
            />
          )
        }

      default:
        return {
          icon: '',
          preview: null
        }
    }
  }, [document])

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
    onDelete(document._id)
    event.stopPropagation()
  }

  return (
    <div className='b-document' onClick={onSelect}>
      <div className='b-document__preview'>
        {preview}
        <div className='b-document__title'>{document.title}</div>
      </div>
      <div className='b-document__text'>{document.title}</div>
      <div className='b-document__type'>
        <img src={icon} alt='' />
      </div>
      <button onClick={handleDelete} className='b-document__delete'>
        <img src={iconDelete} alt='' />
      </button>
      {/* <div className='b-document__backdrop' /> */}
    </div>
  )
}

export default DocumentPreview
