import jwt from 'jwt-decode'
import LocaleService, { t } from './localization'
import { TDocumentType, TFileType, TUnit } from './types'

export const getUserIdFromToken = () => {
  try {
    return (
      jwt<{ id: string }>(localStorage.getItem('token') || '')?.id.toString() ||
      ''
    )
  } catch {
    return ''
  }
}

export const isUrlExternal = (url: string) => {
  return url.includes('http://') || url.includes('https://')
}

export const normalizeUrl = (url: string) => {
  if (!url) {
    return ''
  }

  url = url.replace(/\/\//g, '/')
  url = url.replace('http:/', 'http://')
  url = url.replace('https:/', 'https://')

  url =
    url.charAt(url.length - 1) === '/' ? url.substring(0, url.length - 1) : url

  return url
}

export const parseWord = (value: number = 0, words: string[], unit: TUnit) => {
  if (LocaleService.locale === 'ru') {
    const lastDigit = value % 10

    if (value === 1 || (value > 20 && lastDigit === 1)) {
      return `${value} ${words[1]}`
    }
    if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) {
      return `${value} ${words[2]}`
    }

    return `${value}\xa0${words[0]}`
  } else {
    return `${value}\xa0${t(unit)}`
  }
}

export const getDocumentType: (
  mime: string,
  url?: string
) => null | {
  type: TFileType
  isZip?: boolean
  isModel?: boolean
  docType: TDocumentType
} = (mime, url) => {
  if (
    /^model\/fbx$|^model\/obj$|^model\/gltf-binary$|^application\/octet-stream$/.test(
      mime
    )
  ) {
    return {
      type: 'model',
      docType: 'model',
      isModel: true
    }
  } else if (/^image\//.test(mime)) {
    return { type: 'image', docType: 'image' }
  } else if (/^text\/plain/.test(mime)) {
    return { type: 'document', docType: 'text' }
  } else if (/^application\/pdf/.test(mime)) {
    return { type: 'document', docType: 'pdf' }
  } else if (/^video\/mp4/.test(mime)) {
    return { type: 'video', docType: 'video' }
  } else if (/^application\/zip/.test(mime)) {
    return {
      type: 'model',
      docType: 'zip',
      isZip: true
    }
  } else if (url && /^text\/html/.test(mime)) {
    if (
      /^https?:\/\/(?:(?:www\.)?youtube\.com\/watch\?v=|youtu\.be\/)/.test(url)
    ) {
      return {
        type: 'video',
        docType: 'video'
      }
    }
    if (/^(?!https?:\/\/(?:www\.)?(?:youtu\.be|youtube\.com))/.test(url)) {
      return {
        type: 'link',
        docType: 'link'
      }
    }
  }

  return null
}

export const formatPrice = (price?: number) => {
  if (price && price < 0) {
    return `-$${(-1*price).toFixed(2)}`
  }
  return price ? `$${price.toFixed(2)}` : '$0'
}

const month_2symbols = (d:Date) => {
  const m = d.getMonth() + 1;
  return m < 10 ? `0${m}` : m
}
const lastDayOfMonth = (d: Date) => {
  const y = d.getFullYear();
  const m = d.getMonth();
  const lastDate = new Date(y, m+1, 0);
  return lastDate.getDate();
}

export const getEndDate = (m : number) => {
  const now = new Date();
  const d = new Date(now.setMonth(now.getMonth() + m));
  return `${d.getFullYear()}-${month_2symbols(d)}-${lastDayOfMonth(d)}`;
}
export const getFirstDay = () => {
  const now = new Date();
  const d = new Date(now.setMonth(now.getMonth()+1));
  return `${d.getFullYear()}-${month_2symbols(d)}-01`;
}