import clsx from 'clsx'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Select from 'components/Select'
import client from 'utils/socket'
import confirm from 'utils/confirm'
import Checkbox from 'components/Checkbox'
import iconLink from 'assets/svg/link.svg'
import iconPlus from 'assets/svg/add-square.svg'
import iconImage from 'assets/svg/image.svg'
import iconVideo from 'assets/svg/video-square.svg'
import iconModel from 'assets/svg/3d-rotate.svg'
import iconArrow from 'assets/svg/arrow-up.svg'
import TextField from 'components/TextField'
import iconClose from 'assets/svg/close-circle.svg'
import iconSearch from 'assets/svg/search.svg'
import Typography from 'components/Typography'
import iconDocument from 'assets/svg/document-text.svg'
import DocumentModal from './components/DocumentModal'
import DocumentFilters from './components/DocumentFilters'
import DocumentPreview from './components/DocumentPreview'
import DocumentUploader from './components/DocumentUploader'
import AnalyticsService from 'utils/analytics'
import { t } from 'utils/localization'
import { IDocumentFilter } from 'utils/types'
import { useDocuments, useProfile } from 'utils/hooks/useContext'
import { useEffect, useRef, useState } from 'react'
import './styles.scss'

const Documents = () => {
  const { user, setShowPlanModal } = useProfile()
  const { list, setList, filters, loadList, setFilters, filteredList } =
    useDocuments()

  const searchRef = useRef<HTMLInputElement>(null)
  const isDisabled = false

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [showUploader, setShowUploader] = useState<boolean>(false)
  const [documentToShow, setDocumentToShow] = useState<number>(-1)
  const [showLimitMessage, setShowLimitMessage] = useState<boolean>(false)

  useEffect(() => {
    loadList()
  }, [loadList])

  useEffect(() => {
    if (showSearch) {
      searchRef.current?.focus()
    }
  }, [showSearch])

  // Check if '#upload' is present and open dialog if so
  useEffect(() => {
    const hashValue = window.location.hash.replace(/^#/, '')
    if (hashValue.startsWith('upload')) {
      setShowUploader(true)
    }
  }, [])

  const DOCUMENT_TYPES = [
    {
      icon: '',
      value: 'all',
      label: t('all-types')
    },
    {
      icon: iconImage,
      value: 'image',
      label: t('images')
    },
    {
      icon: iconVideo,
      value: 'video',
      label: t('videos')
    },
    {
      icon: iconModel,
      value: 'model',
      label: t('3d-models')
    },
    {
      icon: iconDocument,
      value: 'document',
      label: t('documents')
    },
    {
      icon: iconLink,
      value: 'link',
      label: t('links')
    }
  ]

  const handleDelete = async (id: string) => {
    if (
      await confirm(t('confirm-delete-file'), {
        okText: t('delete'),
        style: 'warning'
      })
    ) {
      try {
        await client.deleteDoc(id)
        setList((list) => list.filter((item) => item._id !== id))
      } catch (error) {
        console.error({ error })
      }
    }
  }

  const handleHideSearch = () => {
    handleChangeFilters('text')('')
    setShowSearch(false)
  }

  const handleClickUpload = () => {
    AnalyticsService.track('UploadDocuments')

    if (isDisabled) {
      setShowLimitMessage(true)
    } else {
      setShowUploader(true)
    }
  }

  const handleShowPlanModal = () => {
    setShowLimitMessage(false)
    setShowPlanModal(true)
  }

  const handleChangeFilters =
    (name: string, values?: IDocumentFilter) => (value: string | string[]) => {
      const filterValues = values || filters

      if (Array.isArray(value)) {
        if (value.includes('all') && !filterValues.types.includes('all')) {
          value = DOCUMENT_TYPES.map((type) => type.value)
        } else if (
          !value.includes('all') &&
          filterValues.types.includes('all')
        ) {
          value = []
        }

        if (
          filterValues.types.includes('all') &&
          value.length &&
          value.length !== DOCUMENT_TYPES.length
        ) {
          value = value.filter((v) => v !== 'all')
        }

        if (value.length === DOCUMENT_TYPES.length - 1) {
          value.push('all')
        }
      }
      if (!values) {
        setFilters((filters) => ({
          ...filters,
          [name]: value
        }))
      }

      return { ...filterValues, [name]: value }
    }

  return (
    <div className='b-documents'>
      <div className='b-documents__controls'>
        <div
          className={clsx(
            'b-documents__search',
            showSearch
              ? 'b-documents__search--full'
              : 'b-documents__search--collapsed'
          )}
        >
          <TextField
            size='lg'
            prefix={<img src={iconSearch} alt='' />}
            value={filters.text}
            onChange={handleChangeFilters('text')}
            inputRef={searchRef}
            className='b-documents__search-input'
            placeholder={t('document-search')}
            postfix={
              <button onClick={handleHideSearch}>
                <img src={iconClose} alt='' />
              </button>
            }
          />

          <Button size='lg' onClick={() => setShowSearch(true)}>
            <img src={iconSearch} alt='' />
          </Button>

          <Select
            size='lg'
            value={filters.types}
            options={DOCUMENT_TYPES}
            onChange={handleChangeFilters('types')}
            multiple
            className='b-documents__select'
            placeholder={t('all-types')}
            renderLabel={(options) => (
              <div className='b-textfield b-textfield--lg'>
                {options.map(
                  (option) => option.icon && <img src={option.icon} alt='' />
                )}

                <div className='b-textfield__postfix'>
                  <div className='b-select__arrow'>
                    <img src={iconArrow} alt='' />
                  </div>
                </div>
              </div>
            )}
            renderOption={(option, isSelected) => (
              <div className='h-100 b-row b-row__align-center b-row__justify-between'>
                <div className='b-row b-row__align-center'>
                  <Checkbox id={option.value} checked={isSelected} />

                  <Typography>{option.label}</Typography>
                </div>

                <img src={option.icon} alt='' />
              </div>
            )}
          />
        </div>

        <Button
          size='lg'
          className='b-documents__filters'
          onClick={() => setShowFilters(true)}
        >
          <img src={iconSearch} alt='' />
        </Button>

        <Button
          size='lg'
          variant='shadow'
          postfix={<img src={iconPlus} alt='' />}
          onClick={handleClickUpload}
        >
          {t('upload')}
        </Button>
      </div>

      {!filteredList.length && (
        <div>
          <Typography centered weight='semi' color='grey'>
            {t('empty-list')}
          </Typography>
        </div>
      )}

      <div className='b-documents__grid'>
        {filteredList.map((doc, index) => (
          <DocumentPreview
            key={doc._id}
            document={doc}
            onDelete={handleDelete}
            onSelect={() => setDocumentToShow(index)}
          />
        ))}
      </div>

      <div className='b-documents__limit'>
        <Typography size='sm' weight='semi' color='grey'>
          {t('uploaded-doc-all-count',
            {
              1: String(list.length),
              2: String(0)
            }
          )}
        </Typography>
      </div>

      <DocumentUploader
        isOpen={showUploader}
        onClose={() => setShowUploader(false)}
      />

      <DocumentModal
        isOpen={documentToShow > -1}
        onClose={() => setDocumentToShow(-1)}
        document={filteredList[documentToShow]}
        onNext={() =>
          setDocumentToShow(
            filteredList[documentToShow + 1] ? documentToShow + 1 : 0
          )
        }
        onPrev={() =>
          setDocumentToShow(
            filteredList[documentToShow - 1]
              ? documentToShow - 1
              : filteredList.length - 1
          )
        }
      />

      <Modal
        isOpen={showLimitMessage}
        onClose={() => setShowLimitMessage(false)}
        className='b-limit-modal'
      >
        <Typography centered>{t('limit-reached')}</Typography>

        <Button onClick={handleShowPlanModal} fullWidth>
          {t('change-plan')}
        </Button>
      </Modal>

      <DocumentFilters
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        onChange={handleChangeFilters}
        docTypes={DOCUMENT_TYPES}
      />
    </div>
  )
}

export default Documents
