import Button from 'components/Button'
import useTimer from 'utils/hooks/useTimer'
import useRequest from 'utils/hooks/useRequest'
import Typography from 'components/Typography'
import LoadingWrapper from 'components/LoadingWrapper'
import { t } from 'utils/localization'
import { alerts } from 'components/Toast/Toast'
import { useLocation } from 'react-router-dom'

const SuccessAuth = () => {
  const { state } = useLocation()
  const { timer, setTimer, resendInText } = useTimer(60)

  const resendEmailQuery = useRequest('/email-resend', {
    method: 'POST',
    onSuccess() {
      setTimer(60)
      alerts.success(t('email-was-sussesfylly-resend'))
    }
  })

  const handleResendEmail = () => {
    resendEmailQuery.request({ email: (state as { email: string })?.email })
  }

  return (
    <form>
      <LoadingWrapper isLoading={resendEmailQuery.isLoading}>
        <Typography centered size='lg' weight='semi'>
          {t('welcome')}
        </Typography>

        <div className='b-spacer__2' />

        <Typography inline centered>
          {t('confirm-email-text-1')}
          <Typography weight='semi'>
            &nbsp; {(state as { email: string })?.email}&nbsp;
          </Typography>
          {t('confirm-email-text-2')}
        </Typography>

        <div className='b-spacer__5' />

        <Button href='/signin' size='lg' fullWidth>
          {t('great')}
        </Button>

        <div className='b-spacer__2' />

        {timer > 0 ? (
          <Typography centered className='b-link b-link--disabled'>
            {resendInText}
          </Typography>
        ) : (
          <Typography centered>
            {t('letter-not-received')}{' '}
            <button
              type='button'
              onClick={handleResendEmail}
              className='b-link'
            >
              {t('resend-letter')}
            </button>
          </Typography>
        )}
      </LoadingWrapper>
    </form>
  )
}

export default SuccessAuth
