import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Select from 'components/Select/Select'
import TextField from 'components/TextField/TextField'
import Typography from 'components/Typography/Typography'
import { FALLBACK_LOCALE, t } from 'utils/localization'
import { formatPrice, parseWord } from 'utils/helpers'
import { useProfile } from 'utils/hooks/useContext'
import { useEffect, useState } from 'react'
import { PRICE_PER_ROOM } from 'utils/constants'

const SubscriptionModal = () => {
  const profile = useProfile()
  const origin = process.env.REACT_APP_ORIGIN;

  const [numberOfRooms, setNumberOfRooms] = useState(0)
  const [isLoading, setLoading] = useState(false);
  const [priceFromNow, setPriceFromNow] = useState(0);
  const [refillBalance, setRefillBalance] = useState(false);

  useEffect(() => {
    if (profile.user) {
      if (profile.user.cloudData.balance < (priceFromNow * numberOfRooms * 100)) {
        setRefillBalance(true);
      }
    }
    
  }, [numberOfRooms, profile.user])
  
  useEffect(() => {
    if (!profile.slotPriceFromNow) {
      profile.getSlotPriceFromNow();
    }
    setPriceFromNow(profile.slotPriceFromNow/100);
  }, [profile])
  
  useEffect(() => {
    if (!profile.showSubscriptionModal) {
      setTimeout(() => {
        profile.setShowSuccessModal(false)
        setNumberOfRooms(1)
        setLoading(false)
      }, 500)
    }
  }, [profile.showSubscriptionModal])

  if (!profile.user) {
    return null;
  }

  const handleRefill = () => {
    // pushDataLayer({
    //   event: "checkout",
    //   category: "Users",
    //   user_id: profile.user?.authData.id,
    //   origin: window.location.origin,
    // })
    if (profile.user) {
      setLoading(true)
      const amount = (priceFromNow * numberOfRooms * 100) - profile.user.cloudData.balance;
      const data = {
        email: profile.user?.authData.email,
        amount: amount,
        success_url: `${origin}/profile?success=true`,
        cancel_url: `${origin}/profile?success=false`
      }
      try {
        const headers = {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
          'Accept-Language': localStorage.getItem('locale') || FALLBACK_LOCALE
        }
        fetch(`${process.env.REACT_APP_API_HOST}/create-checkout-session`, {
          method: "POST", 
          headers: headers,
          redirect: "follow", 
          body: JSON.stringify(data), 
        }).then( response => {
          if (response.ok) {
            return response.text()
          } else {
            console.log('response not ok', response);
          }
        }).then(url => {
          setLoading(false)
          if (url) {
            localStorage.setItem('purchaseRoomsonRefill', `${numberOfRooms}`)
            window.location.href = url;
          }
        });
      } catch (e) {
        console.log('error init checkout', e);
        setLoading(false)
      }
    }
  }

  const confirm = () => {
    if (profile.user?.cloudData) {
      if (refillBalance) {
        handleRefill();
      } else {
        const roomsTotdal = numberOfRooms+profile.user?.cloudData.slotsRenew;
        profile.setSubscriptionSlots(roomsTotdal, 'add')
        setLoading(true);
      }
      
    }
  }
  const complete = () => {
    profile.setShowSubscriptionModal(false);
    setTimeout(() => {
      profile.setShowSuccessModal(false);
      setLoading(false);
    }, 500)
    
  }

  return (
    <Modal
      isOpen={profile.showSubscriptionModal}
      onClose={() => profile.setShowSubscriptionModal(false)}
      hideClose
      className='b-payment-modal'
    >
      {profile.showSuccessModal ? (
        <>
          <Typography as='h1' centered>
            {t('subscription-is-issued')}
          </Typography>

          <div className='b-spacer__2' />

          <Typography size='md' centered>
            {t('congratulations-on-subscribing')}{' '}
            <b>
              {parseWord(
                numberOfRooms,
                ['комнат', 'комната', 'комнаты'],
                'rooms'
              )}
            </b>{' '}
          </Typography>

          <div className='b-spacer__6' />

          <Button
            size='lg'
            onClick={complete}
            fullWidth
          >
            {t('confirm')}
          </Button>
        </>
      ) : (
        <>
          <Typography as='h1' centered>
            {t('purchase-subscription')}
          </Typography>

          <div className='b-spacer__2' />

          <Typography size='md' centered>
            {t('select-number-of-rooms')}
          </Typography>

          <div className='b-spacer__6' />

          <Select
            size='lg'
            label={t('rooms-number')}
            value={`${numberOfRooms}`}
            onChange={(value) => setNumberOfRooms(Number(value))}
            placeholder={t('select-rooms')}
            options={[
              { label: `1 ${t('room')}`, value: '1' },
              { label: `2 ${t('room')}`, value: '2' },
              { label: `3 ${t('room')}`, value: '3' },
              { label: `4 ${t('room')}`, value: '4' }
            ]}
          />

          {profile.user.cloudData.balance > 0 && <>
            <div className='b-spacer__2' />
            <TextField
              size='lg'
              name='currentBalance'
              label={t('current-balance')}
              value={formatPrice(profile.user.cloudData.balance/100)}
              disabled
            />
          </>}
          
          <div className='b-spacer__2' />

          <TextField
            size='lg'
            name='price'
            label={
              <div className='b-row'>
                <Typography weight='semi'>{t('price')}&nbsp;</Typography>
                <Typography size='md' color='grey'>({t('price-note')})</Typography>
              </div>
              }
            value={formatPrice(priceFromNow * numberOfRooms)}
            disabled
          />

          <div className='b-spacer__2' />

          <TextField
            size='lg'
            name='priceMonthly'
            label={
              <div className='b-row'>
                <Typography weight='semi'>{t('subscription-price-label')}&nbsp;</Typography>
                <Typography size='md' color='grey'>({t('subscription-price-note')})</Typography>
              </div>
              }
            value={formatPrice(numberOfRooms * PRICE_PER_ROOM)}
            disabled
          />

          <div className='b-spacer__6' />

          <div className='b-payment-modal__footer'>
            <Button
              size='lg'
              variant='shadow'
              onClick={() => profile.setShowSubscriptionModal(false)}
            >
              {t('cancel')}
            </Button>

            <Button
              size='lg'
              onClick={confirm}
              disabled={!numberOfRooms || isLoading}
            >
              {refillBalance ? t('pay-width-stripe') : t('confirm')}
            </Button>
          </div>

          <div className='b-spacer__6' />

          <Typography size='md' color='grey' centered>
            {t('subscription-terms')}

            <div className='b-spacer__1' />
            <div>
              <a
                rel='noreferrer'
                href='https://beyondreal.life/pricing/'
                target='_blank'
                className='b-link'
              >
                https://beyondreal.life/pricing/
              </a>
            </div>
          </Typography>
        </>
      )}
    </Modal>
  )
}

export default SubscriptionModal
