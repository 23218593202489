import clsx from 'clsx'
import iconLogo from 'assets/images/Logo.png'
import iconUser from 'assets/svg/user.svg'
// import iconPlans from 'assets/svg/card-tick.svg'
import iconLogout from 'assets/svg/logout.svg'
import iconDocument from 'assets/svg/document-text.svg'
import iconHelp from 'assets/svg/message-question.svg'
import SideMenuItem from './SideMenuItem'
import { t } from 'utils/localization'
import { useLocation } from 'react-router-dom'
import { useDocuments, useProfile } from 'utils/hooks/useContext'

interface SideMenuProps {
  isMenuOpen: boolean
  onOverlayClick(): void
}

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { reset } = useDocuments()
  const { logout } = useProfile()
  const { pathname } = useLocation()
  const { isMenuOpen, onOverlayClick } = props

  const handleLogout = () => {
    onOverlayClick()
    logout()
    reset()
  }

  return (
    <>
      <div
        onClick={onOverlayClick}
        className={clsx(
          'b-side-menu__overlay',
          isMenuOpen && 'b-side-menu__overlay--visible'
        )}
      />

      <aside className={clsx('b-side-menu', isMenuOpen && 'b-side-menu--open')}>
        <div className='b-side-menu__logo'>
          <img src={iconLogo} alt='BEYONDREAL' />
        </div>
        <div className='b-side-menu__list'>
          <SideMenuItem
            url='/profile'
            icon={iconUser}
            label={t('profile')}
            onClick={onOverlayClick}
            isActive={pathname === '/profile'}
          />
          <SideMenuItem
            url='/documents'
            icon={iconDocument}
            label={t('documents')}
            onClick={onOverlayClick}
            isActive={pathname === '/documents'}
          />
          <SideMenuItem
            url='/help'
            icon={iconHelp}
            label={t('help')}
            onClick={onOverlayClick}
            isActive={pathname === '/help'}
          />
          {/* <SideMenuItem
            url='/change-plan'
            icon={iconPlans}
            label={t('plans')}
            onClick={onOverlayClick}
            isActive={pathname === '/change-plan'}
            className='b-side-menu__tariffs'
          /> */}
        </div>
        <div className='b-side-menu__footer'>
          <SideMenuItem
            icon={iconLogout}
            label={t('logout')}
            onClick={handleLogout}
            isActive={false}
          />
        </div>
      </aside>
    </>
  )
}

export default SideMenu
