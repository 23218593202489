import Button from 'components/Button'
import useTimer from 'utils/hooks/useTimer'
import useRequest from 'utils/hooks/useRequest'
import Typography from 'components/Typography'
import LoadingWrapper from 'components/LoadingWrapper'
import { t } from 'utils/localization'
import { alerts } from 'components/Toast/Toast'
import { useProfile } from 'utils/hooks/useContext'

const ConfirmEmail = () => {
  const { timer, setTimer, resendInText } = useTimer(60)
  const { user, setShowEmailConfirmation } = useProfile()

  const resendEmailQuery = useRequest('/email-resend', {
    method: 'POST',
    onSuccess() {
      setTimer(60)
      alerts.success(t('email-was-sussesfylly-resend'))
    }
  })

  const handleResendEmail = () => {
    resendEmailQuery.request({ email: user?.authData.email })
  }

  return (
    <form>
      <LoadingWrapper isLoading={resendEmailQuery.isLoading}>
        <Typography centered size='lg' weight='semi'>
          {t('welcome')}
        </Typography>

        <div className='b-spacer__2' />

        <Typography inline centered>
          {t('confirm-email-text-3')}
          <Typography weight='semi'>
            &nbsp; {user?.authData.email}&nbsp;
          </Typography>
          {t('confirm-email-text-4')}
        </Typography>

        <div className='b-spacer__5' />

        {/* <Button href='/plan' size='lg' fullWidth>
          {t('select-plan')}
        </Button> */}
        <Button
          onClick={() => setShowEmailConfirmation(false)}
          size='lg'
          fullWidth
        >
          {t('signin')}
        </Button>

        <div className='b-spacer__2' />

        {timer > 0 ? (
          <Typography centered className='b-link b-link--disabled'>
            {resendInText}
          </Typography>
        ) : (
          <Typography centered>
            {t('letter-not-received')}{' '}
            <button
              type='button'
              onClick={handleResendEmail}
              className='b-link'
            >
              {t('resend-letter')}
            </button>
          </Typography>
        )}
      </LoadingWrapper>
    </form>
  )
}

export default ConfirmEmail
