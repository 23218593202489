import clsx from 'clsx'
import Modal from 'components/Modal'
import client from 'utils/socket'
import Button from 'components/Button'
import iconLink from 'assets/svg/link.svg'
import iconCloud from 'assets/svg/cloud-upload.svg'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import DocumentType from './DocumentType'
import DocumentFile from './DocumentFile'
import React, { useCallback, useMemo, useState } from 'react'
import { t } from 'utils/localization'
import { alerts } from 'components/Toast/Toast'
import { useDropzone } from 'react-dropzone'
import { getDocumentType } from 'utils/helpers'
import { useDocuments, useProfile } from 'utils/hooks/useContext'
import {
  RichUrl,
  RichFile,
  RichDocument,
  getModelWithTextures
} from 'utils/files'
import {pushDataLayer} from "../../../utils/gtm";

interface DocumentUploaderProps {
  isOpen: boolean
  onClose(): void
}

const DocumentUploader: React.FC<DocumentUploaderProps> = (props) => {
  const { isOpen, onClose } = props
  const { user } = useProfile()
  const { list } = useDocuments()

  const [text, setText] = useState<string>('')
  const [files, setFiles] = useState<RichDocument[]>([])

  const isDisabled = false

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    let parsedFiles: RichFile[] = []

    for (const file of acceptedFiles) {
      const richFile = new RichFile(file)

      if (!richFile.type) {
        alerts.error(t('type-not-supported', { filename: `"${file.name}"` }))
        return
      }

      if (
        (richFile.docType === 'model' && file.size > 5242880) ||
        (richFile.docType === 'video' && file.size > 104857600)
      ) {
        alerts.error(t('file-is-too-large', { filename: `"${file.name}"` }))
        continue
      }

      parsedFiles.push(richFile)
    }

    const model = await getModelWithTextures(parsedFiles)

    if (model) {
      if (model.size > 5242880) {
        alerts.error(t('file-is-too-large', { filename: `"${model.name}"` }))
        return
      }

      const richFile = new RichFile(model)
      parsedFiles = [richFile]
    }

    parsedFiles.forEach(f => {
      pushDataLayer({
        event: 'userFileUploaded',
        mime: f.mime,
        type: f.type,
        docType: f.docType,
      });
    });

    setFiles((files) => files.concat(parsedFiles))
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const canAttachUrl = useMemo(() => {
    const type = getDocumentType('text/html', text)?.type
    return type === 'video' || type === 'link'
  }, [text])

  const handleClose = () => {
    onClose()
    setFiles([])
  }

  const handleAddUrl = async () => {
    const richText = new RichUrl(text)
    setFiles((files) => files.concat(richText))
    setText('')
  }

  const handleDelete = (index: number) => async () => {
    if (files[index].uploadStatus === 'done') {
      await client.deleteDoc(files[index].uploadedId)
    }

    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const handleFileLoad = (index: number) => async (richDoc: RichDocument) => {
    const newFiles = [...files]
    newFiles[index] = richDoc
    setFiles(newFiles)
  }

  const isFilesLoading = files.some((f) => f.uploadStatus === 'loading')

  return (
    <Modal
      isOpen={isOpen}
      onClose={isFilesLoading ? undefined : handleClose}
      className='b-uploader'
      hideClose
      scrollable
    >
      <div className='b-uploader__title'>
        <Typography size='lg' weight='semi'>
          {t('files-upload')}
        </Typography>

        <Typography size='sm' weight='semi' color='grey'>
          {t('uploaded-doc-all-count',
            {
              1: String(list.length),
              2: String(0)
            }
          )}
        </Typography>
      </div>

      <div className='b-uploader__types-wrapper'>
        <div className='b-uploader__types'>
          <DocumentType type='image' />
          <DocumentType type='document' />
          <DocumentType type='model' />
          <DocumentType type='link' />
          <DocumentType type='video' />
        </div>
      </div>

      <div className='b-uploader__files'>
        {files.map((file, index) => (
          <DocumentFile
            key={index}
            richDoc={file}
            onLoad={handleFileLoad(index)}
            onDelete={handleDelete(index)}
          />
        ))}
      </div>

      <div className='b-uploader__input'>
        <TextField
          value={text}
          postfix={<img src={iconLink} alt='' />}
          onChange={setText}
          disabled={isDisabled}
          placeholder={t('video-url')}
        />

        <Button
          onClick={handleAddUrl}
          variant='outline'
          loading={isFilesLoading}
          disabled={!canAttachUrl || isDisabled}
        >
          {t('attach')}
        </Button>
      </div>

      <div
        {...getRootProps({
          className: clsx('b-dropzone', isDisabled && 'b-dropzone--disabled')
        })}
      >
        <input {...getInputProps({ disabled: isDisabled })} />

        <img src={iconCloud} alt='' />

        <div className='b-uploader__lg'>
          <Typography size='md' weight='semi' centered>
            {t('drag-files-here')}
          </Typography>
          <Typography weight='bold' color='primary' centered>
            {t('select-files')}
          </Typography>
        </div>

        <div className='b-uploader__sm'>
          <Typography weight='bold' color='primary' centered>
            {t('upload-files')}
          </Typography>
        </div>
      </div>

      <div className='b-uploader__footer'>
        <Button onClick={handleClose} loading={isFilesLoading}>
          {t('ready')}
        </Button>
      </div>
    </Modal>
  )
}

export default DocumentUploader
